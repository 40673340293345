import './App.css';
import Minter from './Minter'
import Signin from "./signin";
import {Route, Routes} from "react-router";
import {BrowserRouter} from "react-router-dom";
import Register from "./register";
import Confirm from "./Confirm";
import OAuth from "./authorize";
import OAuthStart from "./OAuthStart";
import Pitchup from "./pitchup";
import Redirect from "./redirect";
import Dashboard from "./dashboard";
import AppLayout from "./AppLayout";
import DashboardLayout from './components/sidebar/Layout';
import User from './pages/User';
import Bids from "./pages/Bids";
import MyBids from "./partials/mybids";
import Favourites from "./pages/Favourites";
import CCNFT from "./pages/Ccnft";
import BidHistory from "./pages/BidHistory";
import Settings from "./pages/Settings";

function App() {
  return (
      <BrowserRouter>
      <Routes>
          <Route path="/" element={<DashboardLayout><Dashboard /></DashboardLayout>}/>
        <Route path="/mint" element={<DashboardLayout><Minter /></DashboardLayout>}/>
        <Route path="/auth" element={<DashboardLayout><Signin /></DashboardLayout>}/>
        <Route path="/register" element={<DashboardLayout><Register /></DashboardLayout>}/>
          <Route path="/favourites" element={<DashboardLayout><Favourites/></DashboardLayout>}/>
          <Route path="/bids" element={<DashboardLayout><Bids/></DashboardLayout>}/>
          <Route path="/my-bids" element={<DashboardLayout><MyBids/></DashboardLayout>}/>
          <Route path="/pitchup" element={<Pitchup></Pitchup>}/>
          <Route exact path="/confirm/:token" element={<Confirm></Confirm>}/>
          <Route exact path="/authorize/:callback" element={<OAuth></OAuth>}/>
          <Route exact path="/start/:callback" element={<OAuthStart></OAuthStart>}/>
          <Route path="/nft" element={<DashboardLayout><CCNFT/></DashboardLayout>}/>
          <Route path="/auction/:id" element={<DashboardLayout><BidHistory/></DashboardLayout>}/>
          <Route path="/setting" element={<DashboardLayout><Settings /></DashboardLayout>}/>
      </Routes>
      </BrowserRouter>
  );
}

export default App;
