import { useEffect, useState } from "react";
import {connectWallet, getCurrentWalletConnected, getListOfNFTs, mintNFT, requestSignature} from "./utils/api.js";
import $ from 'jquery';
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckSquare } from "@fortawesome/free-solid-svg-icons";
import { faWarning } from "@fortawesome/free-solid-svg-icons";
import bg from './images/abstract-flowing-neon-wave-background 13.png'
import {Link} from "react-router-dom";
import "./utils/ecsmid";

require('dotenv').config();

const Minter = (props) => {

    //State variables
    const [walletAddress, setWallet] = useState("");
    const [status, setStatus] = useState("");
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [url, setUrl] = useState("");
    let [mintDisabled] = useState(true);
    let [assetUrl] = useState("");
    let [doneWalletStyle] = useState(false);
    const [imageSelected, setImageSelected] = useState(false);
    const [nftList,setNFTList]=useState([]);
    const [message, setMessage]=useState("Welcome to cceNFT, a decentralized application that allows you to create and share secure NFTs. By signing this request, you are proving your identity and ownership of your wallet address without revealing your private key or password. You are also agreeing to our terms and conditions at https://lokdon.com/legal/ . Signing this message will not make any blockchain transaction or cost you any gas fees or money. It is only for authentication purposes. Please review the message carefully and only sign it if you trust the app and understand what it is asking for.")
    const [signIn, setSignIn]=useState(false);
    const [formData, setFormData]=useState({
        timestamp: "",
        location: "",
        title: "",
        description: "",
        file: null
    });


    function addWalletListener() {
        if (window.ethereum) {
            window.ethereum.on("accountsChanged", (accounts) => {
                if (accounts.length > 0) {
                    setWallet(accounts[0]);
                    setStatus("Make sure to prepare the asset");
                } else {
                    setWallet("");
                    setStatus("🦊 Connect to Metamask using the top right button.");
                }
            });
        } else {
            setStatus(
                <p>
                    {" "}
                    🦊{" "}
                    <a target="_blank" href={`https://metamask.io/download.html`}>
                        You must install Metamask, a virtual Ethereum wallet, in your
                        browser.
                    </a>
                </p>
            );
        }
    }


    function setExtra() {
        if (navigator.geolocation === undefined || !navigator.geolocation.hasOwnProperty('getCurrentPosition')) {
            setFormData({...formData, timestamp: Date.now().toString(), location: "330062933:12938388"});
            return;
        }
        navigator.geolocation.getCurrentPosition(function (position) {
            console.log("Latitude is :", position.coords.latitude);
            console.log("Longitude is :", position.coords.longitude);

            setFormData({...formData, timestamp: position.timestamp.toString(), location:  position.coords.latitude + ":" + position.coords.longitude});
        });
    }

    function encrypt(val){
        return window.main.api.encrypt(val);
    }

    useEffect(async () => {
        window.main();
        const { address, status } = await getCurrentWalletConnected();
        setWallet(address)
        setStatus(status);
        addWalletListener();
        setExtra();
    }, []);
    useEffect(async ()=>{
        if(walletAddress.length>0 && signIn===false){
            let msg=message;
            msg=msg+"\r\n\r\nAccount: "+walletAddress;
            requestSignature(walletAddress,msg)
                .then(async (result)=>{
                    setSignIn(true);
                    console.log("Listing NFTs from OWNER");
                    const nfts=await getListOfNFTs(walletAddress);
                    if(nfts){
                        const ownedNFTs=nfts.ownedNfts;
                        let nftArr=[...nftList];
                        for (let i=0;i<ownedNFTs.length;i++){
                            nftArr.push(ownedNFTs[i].rawMetadata);
                        }
                        console.log("nfts",nftArr);
                        setNFTList([...nftArr]);
                    }
                })
                .catch((err)=>console.log("error",err));
        }
    },[walletAddress]);

    const connectWalletPressed = async () => {
        const walletResponse = await connectWallet();
        setStatus(walletResponse.status);
        setWallet(walletResponse.address);
    };

    const onMintPressed = async () => { //TODO: implement
        console.log('mint pressed', url, name, description);
        if (url === "" || name === "" || description === "") {
            alert('Error: please prepare the assets first!');
        } else {
            const { status } = await mintNFT(url, encrypt(name), encrypt(description));
            setStatus(status);
        }
    };

    const prepareAsset = (event) => {
        event.preventDefault();
        const data=formData;
        setName(data.title);
        setDescription(data.description);
        console.log("data",data);
        console.log("event",event);
        if (walletAddress.length > 0) {
            $.ajax({
                url: 'https://ccnft.lokdon.com/api/upload',
                type: 'POST',
                data: JSON.stringify(data),
                processData: false,
                contentType: false,
                success: (rs) => {
                    console.log("response",rs);
                    if (rs.status === 'success') {
                        console.log('asset is now ready');
                        console.log(rs);
                        alert('Encryption completed, you can mint NFT now');


                        setImageSelected(true);
                        setUrl("https://gateway.pinata.cloud/ipfs/" + rs.hash);
                        mintDisabled = false;
                    } else {
                        alert('Server can not handle your request at this time, please wait a few minutes and try again!');
                    }
                },
                error(er) {
                    console.log(er);
                }
            });

        } else {
            alert('You need to select your web3 wallet address from Meta-Mask');
        }
    }

    function titleChanged(e) {
        setName(e.target.value);
    }
    function descriptionChanged(e){
        setDescription(e.target.value);
    }

    async function getBase64(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = () => {
                resolve(reader.result)
            }
            reader.onerror = reject
        });
    }

    function readFile(file) {
        getBase64(file)
            .then((result)=>{
                console.log("base64",result);
                setFormData({...formData,file: result});
            })
            .catch((err)=>{console.log(err);})
    }

    return (
        <main
            style={{
                backgroundImage:
                    'url("../assets/images/abstract-flowing-neon-wave-background 13.png")',
            }}
            className=" h-screen w-full overflow-x-hidden bg-cover bg-center p-12 relative  bg-[#441F64] "
        >
            <header className="flex flex-row items-center  md:mt-4 md:mb-5 mt-0 mb-0   ">
                <h1>
                    <img
                        className="md:h-32 md:w-40 h-20 w-32"
                        //   style={{ width: "100px" }}
                        src="/styles/images/logo.png"
                        alt="cceNFT Demo"
                    />
                </h1>
                <h1
                    //   style={{ fontSize: "32px" }}
                    className="lg:text-4xl md:text-2xl text-base font-bold"
                >
                    TRY OUR{" "}
                    <Link
                        to="/auth"
                        //   style={{ color: "blue" }}
                    >
                        cceNFT AUTHENTICATION <br />
                        DEMO HERE.
                    </Link>
                </h1>
            </header>
            <div className=" flex md:flex-row flex-col-reverse  mt-3 space-x-20">
                <section className=" lg:w-[55%] md:w-[70%] w-full flex justify-center items-center lg:ml-40 ml-0">
                    <section className=" backdrop-blur-sm bg-[#301841]/40  flex flex-col rounded-2xl ">
                        <div className=" p-10">
                            <p className="lg:text-xl text-base md:mb-4 mb-0 lg:w-4/5 w-full ">
                                Prepare your NFT asset before minting, choose your image file,{" "}
                                enter your name and & click prepare asset
                            </p>
                            <div className="">
                                <h3 className="text-base font-bold mb-4">ECSMID cceNFT Demo</h3>
                            </div>
                            <form
                                action="/v1/encrypt"
                                className="signup"
                                encType="multipart/form-data"
                                id="form"
                                onSubmit={prepareAsset}
                            >
                                <div className="inp-cont ">
                                    <label htmlFor="timestamp">
                                        <img src="/styles/images/timeStamp.svg" alt="" />
                                    </label>
                                    <input
                                        type="text"
                                        name="timestamp"
                                        id="timestamp"
                                        placeholder="Auto Timestamp"
                                        className=""
                                        value={formData.timestamp}
                                        onChange={(e)=>{setFormData({...formData, timestamp: e.target.value});}}
                                    />
                                </div>
                                <div className="inp-cont">
                                    <label htmlFor="location">
                                        <img src="/styles/images/location.svg" alt="" />
                                    </label>
                                    <input
                                        type="text"
                                        name="location"
                                        id="location"
                                        placeholder="Open Location Code"
                                        value={formData.location}
                                        onChange={(e)=>{setFormData({...formData, location: e.target.value});}}
                                    />
                                </div>
                                <div className="inp-cont">
                                    <label htmlFor="fullname">
                                        <img src="/styles/images/fullName.svg" alt="" />
                                    </label>
                                    <input
                                        type="text"
                                        name="title"
                                        id="title"
                                        onChange={titleChanged}
                                        value={name}
                                        placeholder="Document Title"
                                        value={formData.title}
                                        onChange={(e)=>{setFormData({...formData, title: e.target.value});}}
                                    />
                                </div>
                                <div className="inp-cont">
                                    <label htmlFor="description">
                                        <img src="/styles/images/fullName.svg" alt="" />
                                    </label>
                                    <input
                                        type="text"
                                        name="description"
                                        id="description"
                                        onChange={descriptionChanged}
                                        value={description}
                                        placeholder="Document Description"
                                        value={formData.description}
                                        onChange={(e)=>{setFormData({...formData, description: e.target.value});}}
                                    />
                                </div>
                                <div className="inp-cont">
                                    <label htmlFor="asset">
                                        <img src="/styles/images/images.svg" alt="" />
                                    </label>
                                    <input
                                        type="file"
                                        name="file"
                                        id="asset"
                                        placeholder="Select File "
                                        onChange={(e)=>{readFile(e.target.files[0]);e.target.value=null;}}
                                    />
                                    <button type="submit" id="submit">
                                        Prepare Assets
                                    </button>
                                </div>
                            </form>
                            <div className="flex space-x-4 ">
                                <label htmlFor="wallet">
                                    <img src="/styles/images/wallet.svg" alt="" />
                                </label>
                                <button
                                    type="submit"
                                    id="walletButton"
                                    onClick={connectWalletPressed}
                                >
                                    {walletAddress.length > 0 ? (
                                        "Connected: " +
                                        String(walletAddress).substring(0, 6) +
                                        "..." +
                                        String(walletAddress).substring(38)
                                    ) : (
                                        <span className=" font-bold">Connect Wallet</span>
                                    )}
                                </button>
                            </div>
                        </div>
                    </section>
                </section>
                <section className=" md:w-[20%] w-full ">
                    <div className="heading">
                        <h3>
              <span className="border-bottom uppercase lg:text-xl text-base">
                Requirements
              </span>
                        </h3>
                    </div>
                    <ul className=" my-2">
                        <li className=" lg:text-xl text-sm">
                            <FontAwesomeIcon
                                size="lg"
                                icon={walletAddress.length > 5 ? faCheckSquare : faWarning}
                                color="#f77a00"
                            />{" "}
                            Wallet connected
                        </li>
                        <li className=" lg:text-xl text-sm">
                            <FontAwesomeIcon
                                size="lg"
                                icon={imageSelected ? faCheckSquare : faWarning}
                                color="#f77a00"
                            />{" "}
                            Assets Prepared
                        </li>
                    </ul>
                    <button
                        className="bg-[#f77a00] px-4 py-2  rounded-md mt-1 font-semibold lg:text-xl text-sm  text-gray-800"
                        id="mintButton"
                        onClick={onMintPressed}
                    >
                        Mint cceNFT
                    </button>
                </section>
            </div>
            <div className=" lg:w-[50%] w-full  mt-3  lg:ml-40 ml-0">
                <p className="flex space-x-4  backdrop-opacity-10 backdrop-invert   bg-[#1C182D]/30 px-4 py-2 rounded-lg">
                    <img src="/styles/images/info_icon.svg" alt="" />
                    <span className="">{status}</span>
                </p>
            </div>
            <div className="absolute lg:bottom-0 lg:top-[20rem] md:bottom-[10rem] md:right-0  -bottom-[10rem] lg:right-0 md:block hidden" style={{marginTop: "50px"}}>
                <div className=" lg:w-[38rem] md:w-[25rem] w-[10rem] ">
                    <img src="/styles/images/BOY_BG_WITH_SHADOW.png" alt="" />
                </div>
            </div>
        </main>
         );
};

export default Minter;
