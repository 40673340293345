import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router";
import {toast} from "react-toastify";

const Confirm=(props)=>{
    const {token}=useParams();
    const [BASE_URL] = useState("https://activation.lokdon.com/stegano");
    const {navigate} = useNavigate();
    useEffect(()=>{
        toast.configure();
        verifyEmail(token);
    },[])
    return(
        <h2>You confirmed your email successfully</h2>
    );
    function showErr(msg) {

        toast(msg, {
            type: "error",
        });
    }
    function showInfo(msg) {
        toast(msg, {
            type: "success"
        });
    }
    function verifyEmail(verificationToken){
        let body={
            token: verificationToken,
        };
        console.log("body: ",body);
        fetch(BASE_URL+"/confirm.php",{
            method: "POST",
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
            }
        }).then((response)=>response.json())
            .then((result)=>{
                if(result.status==='success'){
                    showInfo("You have successfully verified your email address");
                    navigate('/auth');
                }else{
                    showErr(result.message);
                    setTimeout(()=>{
                        navigate("/auth");
                    },3000);
                }
            })
            .then((err)=>{
               console.log(err);
            });

    }
}
export default Confirm;