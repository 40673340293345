import React from "react";
import Vid from "../images/vid.svg";
import user from "../images/user.png";
import user1 from "../images/user1.png";
import user2 from "../images/user2.png";
import verify from "../images/verify1.png";
import verify1 from "../images/verify2.png";
import verify2 from "../images/verify3.png";

function User() {
  return (
    <section className=" mt-6 ">
      <div className="flex lg:flex-row flex-col lg:space-x-10 space-x-0 lg:space-y-0 space-y-3  ">
        <div
          className="h-[300px] lg:w-[65%]  w-[100%] object-cover bg-cover bg-center rounded-lg"
          style={{
            backgroundImage:
              'url("../assets/images/abstract-flowing-neon-wave-background 13.png")',
          }}
        >
          <div className="grid md:grid-cols-2 grid-cols-1 gap-3 mt-6">
            <div className="flex flex-col ml-7 lg:mt-7 mt-2 space-y-4">
              <div className=" lg:text-xl md:text-sm sm:text-xs font-normal">
                SECURE DIGITAL ASSETS
              </div>
              <div className=" lg:text-3xl md:text-xl text-base font-semibold">
                WELCOME, TRY THE MOST SECURE NFT
              </div>
              <button className=" flex py-2 px-2 md:w-[9rem]  items-center   w-28 rounded-2xl lg:text-sm text-xs text-black bg-[#FFB613]  font-extrabold">
                <span className="mr-1">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M2.8041 8.353C2.5241 10.956 2.5361 13.958 2.9261 16.55C3.03079 17.2469 3.36698 17.8882 3.88052 18.3707C4.39407 18.8532 5.05508 19.1489 5.7571 19.21L7.2671 19.341C10.4171 19.615 13.5831 19.615 16.7331 19.341L18.2431 19.211C18.9451 19.1499 19.6061 18.8542 20.1197 18.3717C20.6332 17.8892 20.9694 17.2479 21.0741 16.551C21.4641 13.958 21.4761 10.956 21.1961 8.353C21.16 8.05107 21.1193 7.74971 21.0741 7.449C20.9694 6.75215 20.6332 6.11081 20.1197 5.62828C19.6061 5.14575 18.9451 4.85012 18.2431 4.789L16.7331 4.659C13.5837 4.3852 10.4165 4.3852 7.2671 4.659L5.7571 4.789C5.05508 4.85012 4.39407 5.14575 3.88052 5.62828C3.36698 6.11081 3.03079 6.75215 2.9261 7.449C2.88102 7.74972 2.84035 8.05109 2.8041 8.353ZM7.3971 6.153C10.4597 5.88677 13.5395 5.88677 16.6021 6.153L18.1121 6.284C18.4787 6.31601 18.8239 6.47039 19.0922 6.72233C19.3604 6.97427 19.5362 7.30911 19.5911 7.673L19.6251 7.906L14.0641 10.996C13.4327 11.3468 12.7224 11.5308 12.0001 11.5308C11.2778 11.5308 10.5675 11.3468 9.9361 10.996L4.3751 7.906L4.4091 7.673C4.46365 7.30912 4.63913 6.9742 4.90725 6.72222C5.17538 6.47024 5.52054 6.31588 5.8871 6.284L7.3971 6.153ZM19.8081 9.52C20.0025 11.7897 19.9296 14.0743 19.5911 16.327C19.5366 16.6909 19.3611 17.0258 19.0929 17.2778C18.8248 17.5298 18.4797 17.6841 18.1131 17.716L16.6031 17.847C13.5402 18.1132 10.46 18.1132 7.3971 17.847L5.8871 17.716C5.52054 17.6841 5.17538 17.5298 4.90725 17.2778C4.63913 17.0258 4.46365 16.6909 4.4091 16.327C4.07025 14.0744 3.99709 11.7897 4.1911 9.52L9.2071 12.307C10.0613 12.7816 11.0224 13.0306 11.9996 13.0306C12.9768 13.0306 13.9379 12.7816 14.7921 12.307L19.8081 9.52Z"
                      fill="#33240A"
                    />
                  </svg>
                </span>{" "}
                MINT cceNFT
              </button>
            </div>
            <div className=" md:-ml-20 lg:mt-[0rem] md:mt-[3rem] mt-[-4rem]  ml-10 ">
              <div className=" lg:w-[25rem] md:w-[20rem] w-[15rem]  ">
                <img src="/styles/images/BOY_BG_WITH_SHADOW.png" alt="" />
              </div>
            </div>
          </div>
        </div>
        <div className="h-[300px] lg:w-[27%] w-[100%] object-cover bg-cover bg-center rounded-lg  bg-[url('assets/ccNFT/auth-bg.png')] ">
          <div className=" flex flex-col justify-center items-center  lg:mt-10 mt-4 ">
            <div className=" md:text-xl   text-sm font-medium px-16 lg:my-5 md:my-10 my-10">
              ECSMID color-coded encrypted non-fungible Token (cceNFT)
            </div>
            <img src={Vid} alt="videos" />
          </div>
        </div>
      </div>
      <div>
        <h4 className="text-[20px] font-semibold my-6 ">Featured NFT Codes</h4>
        <div className=" grid lg:grid-cols-3 grid-cols-1  lg:mx-32 mx-0 mr-7 lg:mr-0 gap-5 lg:space-y-0 space-y-2 mb-8">
          <div className=" lg:w-[325px] w-full   h-[430px] bg-black   rounded-xl py-4 flex flex-col justify-center items-center  ">
            <div className="flex text-xs justify-between space-x-12 px-8 mb-3 items-center">
              <div className=" ">
                ECSMID Digital Codex NFT III <br />
                Owner : cceNFT - BRYAN
              </div>
              <div className="flex space-x-1 items-center">
                <svg
                  width="17"
                  height="14"
                  viewBox="0 0 17 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.1638 0C10.6917 0 9.38517 0.555087 8.5 1.50916C7.61483 0.555087 6.30832 0 4.83621 0C3.55404 0.00148193 2.32483 0.488695 1.41821 1.35477C0.511577 2.22085 0.00155132 3.39507 0 4.61989C0 9.68357 7.75332 13.7295 8.08306 13.8996C8.2112 13.9655 8.35446 14 8.5 14C8.64554 14 8.7888 13.9655 8.91694 13.8996C9.24668 13.7295 17 9.68357 17 4.61989C16.9984 3.39507 16.4884 2.22085 15.5818 1.35477C14.6752 0.488695 13.446 0.00148193 12.1638 0ZM11.7615 9.96496C10.741 10.7922 9.65003 11.5368 8.5 12.1909C7.34997 11.5368 6.25903 10.7922 5.23849 9.96496C3.6506 8.66369 1.75862 6.67924 1.75862 4.61989C1.75862 3.84017 2.08287 3.09239 2.66002 2.54105C3.23718 1.9897 4.01998 1.67996 4.83621 1.67996C6.14052 1.67996 7.23233 2.33794 7.68591 3.39772C7.75192 3.55221 7.86461 3.68442 8.00961 3.77748C8.1546 3.87054 8.32532 3.92024 8.5 3.92024C8.67468 3.92024 8.8454 3.87054 8.99039 3.77748C9.13539 3.68442 9.24808 3.55221 9.3141 3.39772C9.76767 2.33794 10.8595 1.67996 12.1638 1.67996C12.98 1.67996 13.7628 1.9897 14.34 2.54105C14.9171 3.09239 15.2414 3.84017 15.2414 4.61989C15.2414 6.67924 13.3494 8.66369 11.7615 9.96496Z"
                    fill="white"
                    fill-opacity="0.8"
                  />
                </svg>
                <span>212</span>
              </div>
            </div>
            <div
              className="lg:w-[280px] w-[80%] h-[300px] object-cover bg-cover bg-center rounded-lg"
              style={{
                backgroundImage:
                  'url("../assets/images/abstract-flowing-neon-wave-background 13.png")',
              }}
            >
              <div className="flex justify-between px-3 mt-2">
                <div className="  bg-[#FAFBFC] p-2 rounded-full">
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M14.9994 6C15.6086 6.00002 16.2033 5.8146 16.7045 5.4684C17.2057 5.1222 17.5897 4.63162 17.8053 4.06191C18.0209 3.49219 18.058 2.87034 17.9116 2.27904C17.7652 1.68774 17.4423 1.15501 16.9858 0.751702C16.5293 0.348394 15.9608 0.0936156 15.3559 0.0212481C14.7511 -0.0511194 14.1386 0.0623529 13.5998 0.346575C13.061 0.630798 12.6215 1.0723 12.3397 1.61238C12.058 2.15245 11.9473 2.7655 12.0224 3.37L5.08244 6.84C4.65867 6.43136 4.12404 6.15642 3.54513 6.04945C2.96623 5.94247 2.36865 6.00819 1.82683 6.2384C1.285 6.46862 0.822889 6.85317 0.498049 7.34414C0.173208 7.83511 0 8.4108 0 8.9995C0 9.58821 0.173208 10.1639 0.498049 10.6549C0.822889 11.1458 1.285 11.5304 1.82683 11.7606C2.36865 11.9908 2.96623 12.0565 3.54513 11.9496C4.12404 11.8426 4.65867 11.5676 5.08244 11.159L12.0224 14.629C11.9349 15.3312 12.0985 16.0418 12.4842 16.6351C12.8699 17.2284 13.453 17.6663 14.1303 17.8713C14.8076 18.0763 15.5356 18.0353 16.1856 17.7555C16.8356 17.4757 17.3658 16.9751 17.6824 16.3422C17.999 15.7093 18.0817 14.9849 17.9159 14.2969C17.75 13.6089 17.3463 13.0017 16.7761 12.5826C16.2059 12.1635 15.5059 11.9595 14.7998 12.0066C14.0937 12.0537 13.427 12.3489 12.9174 12.84L5.97744 9.37C6.00768 9.12426 6.00768 8.87574 5.97744 8.63L12.9174 5.16C13.4554 5.68 14.1894 6 14.9994 6Z"
                      fill="black"
                      fill-opacity="0.8"
                    />
                  </svg>
                </div>
                <div className=" bg-[#FAFBFC] text-black p-2 text-sm rounded-xl ">
                  02 : 10 : 40 : 52 left
                </div>
              </div>
              <img src={user2} alt="" className="mt-4" />
              <img src={verify1} alt="" className="h-14 w-14  ml-40 -mt-10" />
            </div>
            <div className="flex text-xs font-semibold justify-between  space-x-10 mt-7 px-3">
              <div className="">
                Current Bid <br /> 0.08935 ETH
              </div>
              <div>ECSMID - NFT III</div>
            </div>
          </div>
          <div className=" lg:w-[325px] w-full h-[430px] bg-black   rounded-xl flex flex-col justify-center items-center ">
            <div className="flex text-xs justify-between space-x-12 px-8 mb-3 items-center">
              <div className=" ">
                ECSMID Digital Codex NFT III <br />
                Owner : cceNFT - BRYAN
              </div>
              <div className="flex space-x-1 items-center">
                <svg
                  width="17"
                  height="14"
                  viewBox="0 0 17 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.1638 0C10.6917 0 9.38517 0.555087 8.5 1.50916C7.61483 0.555087 6.30832 0 4.83621 0C3.55404 0.00148193 2.32483 0.488695 1.41821 1.35477C0.511577 2.22085 0.00155132 3.39507 0 4.61989C0 9.68357 7.75332 13.7295 8.08306 13.8996C8.2112 13.9655 8.35446 14 8.5 14C8.64554 14 8.7888 13.9655 8.91694 13.8996C9.24668 13.7295 17 9.68357 17 4.61989C16.9984 3.39507 16.4884 2.22085 15.5818 1.35477C14.6752 0.488695 13.446 0.00148193 12.1638 0ZM11.7615 9.96496C10.741 10.7922 9.65003 11.5368 8.5 12.1909C7.34997 11.5368 6.25903 10.7922 5.23849 9.96496C3.6506 8.66369 1.75862 6.67924 1.75862 4.61989C1.75862 3.84017 2.08287 3.09239 2.66002 2.54105C3.23718 1.9897 4.01998 1.67996 4.83621 1.67996C6.14052 1.67996 7.23233 2.33794 7.68591 3.39772C7.75192 3.55221 7.86461 3.68442 8.00961 3.77748C8.1546 3.87054 8.32532 3.92024 8.5 3.92024C8.67468 3.92024 8.8454 3.87054 8.99039 3.77748C9.13539 3.68442 9.24808 3.55221 9.3141 3.39772C9.76767 2.33794 10.8595 1.67996 12.1638 1.67996C12.98 1.67996 13.7628 1.9897 14.34 2.54105C14.9171 3.09239 15.2414 3.84017 15.2414 4.61989C15.2414 6.67924 13.3494 8.66369 11.7615 9.96496Z"
                    fill="white"
                    fill-opacity="0.8"
                  />
                </svg>
                <span>212</span>
              </div>
            </div>
            <div
              className="lg:w-[280px]  w-[80%] h-[300px] object-cover bg-cover bg-center rounded-lg"
              style={{
                backgroundImage:
                  'url("../assets/images/abstract-flowing-neon-wave-background 13.png")',
              }}
            >
              <div className="flex justify-between px-3 mt-2">
                <div className="  bg-[#FAFBFC] p-2 rounded-full">
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M14.9994 6C15.6086 6.00002 16.2033 5.8146 16.7045 5.4684C17.2057 5.1222 17.5897 4.63162 17.8053 4.06191C18.0209 3.49219 18.058 2.87034 17.9116 2.27904C17.7652 1.68774 17.4423 1.15501 16.9858 0.751702C16.5293 0.348394 15.9608 0.0936156 15.3559 0.0212481C14.7511 -0.0511194 14.1386 0.0623529 13.5998 0.346575C13.061 0.630798 12.6215 1.0723 12.3397 1.61238C12.058 2.15245 11.9473 2.7655 12.0224 3.37L5.08244 6.84C4.65867 6.43136 4.12404 6.15642 3.54513 6.04945C2.96623 5.94247 2.36865 6.00819 1.82683 6.2384C1.285 6.46862 0.822889 6.85317 0.498049 7.34414C0.173208 7.83511 0 8.4108 0 8.9995C0 9.58821 0.173208 10.1639 0.498049 10.6549C0.822889 11.1458 1.285 11.5304 1.82683 11.7606C2.36865 11.9908 2.96623 12.0565 3.54513 11.9496C4.12404 11.8426 4.65867 11.5676 5.08244 11.159L12.0224 14.629C11.9349 15.3312 12.0985 16.0418 12.4842 16.6351C12.8699 17.2284 13.453 17.6663 14.1303 17.8713C14.8076 18.0763 15.5356 18.0353 16.1856 17.7555C16.8356 17.4757 17.3658 16.9751 17.6824 16.3422C17.999 15.7093 18.0817 14.9849 17.9159 14.2969C17.75 13.6089 17.3463 13.0017 16.7761 12.5826C16.2059 12.1635 15.5059 11.9595 14.7998 12.0066C14.0937 12.0537 13.427 12.3489 12.9174 12.84L5.97744 9.37C6.00768 9.12426 6.00768 8.87574 5.97744 8.63L12.9174 5.16C13.4554 5.68 14.1894 6 14.9994 6Z"
                      fill="black"
                      fill-opacity="0.8"
                    />
                  </svg>
                </div>
                <div className=" bg-[#FAFBFC] text-black p-2 text-sm rounded-xl ">
                  02 : 10 : 40 : 52 left
                </div>
              </div>
              <img src={user1} alt="" className="mt-4" />
              <img src={verify2} alt="" className="h-14 w-14  ml-40 -mt-10" />
            </div>
            <div className="flex text-xs font-semibold justify-between  space-x-10 mt-7 px-3">
              <div>
                Current Bid <br /> 0.08935 ETH
              </div>
              <div>ECSMID - NFT III</div>
            </div>
          </div>
          <div className=" lg:w-[325px] w-full  h-[430px] bg-black  rounded-xl flex flex-col justify-center items-center ">
            <div className="flex text-xs justify-between space-x-12 px-8 mb-3 items-center">
              <div className=" ">
                ECSMID Digital Codex NFT III <br />
                Owner : cceNFT - BRYAN
              </div>
              <div className="flex space-x-1 items-center">
                <svg
                  width="17"
                  height="14"
                  viewBox="0 0 17 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.1638 0C10.6917 0 9.38517 0.555087 8.5 1.50916C7.61483 0.555087 6.30832 0 4.83621 0C3.55404 0.00148193 2.32483 0.488695 1.41821 1.35477C0.511577 2.22085 0.00155132 3.39507 0 4.61989C0 9.68357 7.75332 13.7295 8.08306 13.8996C8.2112 13.9655 8.35446 14 8.5 14C8.64554 14 8.7888 13.9655 8.91694 13.8996C9.24668 13.7295 17 9.68357 17 4.61989C16.9984 3.39507 16.4884 2.22085 15.5818 1.35477C14.6752 0.488695 13.446 0.00148193 12.1638 0ZM11.7615 9.96496C10.741 10.7922 9.65003 11.5368 8.5 12.1909C7.34997 11.5368 6.25903 10.7922 5.23849 9.96496C3.6506 8.66369 1.75862 6.67924 1.75862 4.61989C1.75862 3.84017 2.08287 3.09239 2.66002 2.54105C3.23718 1.9897 4.01998 1.67996 4.83621 1.67996C6.14052 1.67996 7.23233 2.33794 7.68591 3.39772C7.75192 3.55221 7.86461 3.68442 8.00961 3.77748C8.1546 3.87054 8.32532 3.92024 8.5 3.92024C8.67468 3.92024 8.8454 3.87054 8.99039 3.77748C9.13539 3.68442 9.24808 3.55221 9.3141 3.39772C9.76767 2.33794 10.8595 1.67996 12.1638 1.67996C12.98 1.67996 13.7628 1.9897 14.34 2.54105C14.9171 3.09239 15.2414 3.84017 15.2414 4.61989C15.2414 6.67924 13.3494 8.66369 11.7615 9.96496Z"
                    fill="white"
                    fill-opacity="0.8"
                  />
                </svg>
                <span>212</span>
              </div>
            </div>
            <div
              className="lg:w-[280px]  w-[80%] h-[300px] object-cover bg-cover bg-center rounded-lg"
              style={{
                backgroundImage:
                  'url("../assets/images/abstract-flowing-neon-wave-background 13.png")',
              }}
            >
              <div className="flex justify-between px-1 mt-2">
                <div className="  bg-[#FAFBFC] p-2 rounded-full">
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M14.9994 6C15.6086 6.00002 16.2033 5.8146 16.7045 5.4684C17.2057 5.1222 17.5897 4.63162 17.8053 4.06191C18.0209 3.49219 18.058 2.87034 17.9116 2.27904C17.7652 1.68774 17.4423 1.15501 16.9858 0.751702C16.5293 0.348394 15.9608 0.0936156 15.3559 0.0212481C14.7511 -0.0511194 14.1386 0.0623529 13.5998 0.346575C13.061 0.630798 12.6215 1.0723 12.3397 1.61238C12.058 2.15245 11.9473 2.7655 12.0224 3.37L5.08244 6.84C4.65867 6.43136 4.12404 6.15642 3.54513 6.04945C2.96623 5.94247 2.36865 6.00819 1.82683 6.2384C1.285 6.46862 0.822889 6.85317 0.498049 7.34414C0.173208 7.83511 0 8.4108 0 8.9995C0 9.58821 0.173208 10.1639 0.498049 10.6549C0.822889 11.1458 1.285 11.5304 1.82683 11.7606C2.36865 11.9908 2.96623 12.0565 3.54513 11.9496C4.12404 11.8426 4.65867 11.5676 5.08244 11.159L12.0224 14.629C11.9349 15.3312 12.0985 16.0418 12.4842 16.6351C12.8699 17.2284 13.453 17.6663 14.1303 17.8713C14.8076 18.0763 15.5356 18.0353 16.1856 17.7555C16.8356 17.4757 17.3658 16.9751 17.6824 16.3422C17.999 15.7093 18.0817 14.9849 17.9159 14.2969C17.75 13.6089 17.3463 13.0017 16.7761 12.5826C16.2059 12.1635 15.5059 11.9595 14.7998 12.0066C14.0937 12.0537 13.427 12.3489 12.9174 12.84L5.97744 9.37C6.00768 9.12426 6.00768 8.87574 5.97744 8.63L12.9174 5.16C13.4554 5.68 14.1894 6 14.9994 6Z"
                      fill="black"
                      fill-opacity="0.8"
                    />
                  </svg>
                </div>
                <div className=" bg-[#FAFBFC] text-black p-2 text-sm rounded-xl ">
                  02 : 10 : 40 : 52 left
                </div>
              </div>
              <img src={user} alt="" className="mt-4" />
              <img src={verify} alt="" className="h-14 w-14  ml-40 -mt-10" />
            </div>
            <div className="flex text-xs font-semibold justify-between  space-x-10 mt-7 px-1">
              <div>
                Current Bid <br /> 0.08935 ETH
              </div>
              <div>ECSMID - NFT III</div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default User;
