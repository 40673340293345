import React, {Children, useEffect, useState} from "react";
import { Sidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Link } from "react-router-dom";
// import Logo from "./images/logo.png";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faHeart,
    faSquarePollVertical,
    faWallet,
    faBookBookmark,
    faUser,
    faGear,
    faSquare,
    faGem,
    faChevronRight,
    faChevronLeft,
} from "@fortawesome/free-solid-svg-icons";
import TopBar from "./components/TopBar";
import "./utils/ecsmid";


// import { faCheckSquare, faCoffee } from "@fortawesome/fontawesome-free-solid";

function Dashboard({ children }) {
    const [collapsed, setCollapsed] = useState(false);
    const [toggled, setToggled] = useState(false);
    const [userData,setUserData]=useState({
        email: "",
        name: ""
    });

    useEffect(()=>{
        window.main();
        //window.addEventListener("storage",onUserDataChange);
        window.localStorageChanged=(data)=>{
            setUserData({...userData, email: data.email, name: data.name});
        };
        if (localStorage.getItem("name") && localStorage.getItem("email")) {
            let name=decrypt(localStorage.getItem("name"));
            let email=decrypt(localStorage.getItem("email"));
            let data=userData;
            data.email=email;
            data.name=name;
            setUserData({...userData, email: data.email, name: data.name});
        }
    },[]);

    const handleCollapsedChange = () => {
        setCollapsed(!collapsed);
    };
    const handleToggleSidebar = (value) => {
        setToggled(value);
    };
    const onUserDataChange=(event)=>{
        console.log("EVENT localStorage",event);
        if(event.key === "email"){
            let data=userData;
            data.email=decrypt(event.newValue);
            setUserData({...userData,email: data.email});
        }
        if(event.key==="name"){
            let data=userData;
            data.name=decrypt(event.newValue);
            setUserData({...userData,name: data.name});
        }
    }
    function encrypt(val) {
        return window.main.api.encrypt(val);
    }

    function decrypt(val) {
        return window.main.api.decrypt(val);
    }
    return (
        <div className="bg-[url('assets/ccNFT/bg-user.png')] object-cover  min-h-screen min-w-screen bg-cover bg-center text-white">
            <div
                style={{
                    display: "flex",
                    height: "100%",
                    width: "100%",
                    color: "white",
                }}
            >
                <Sidebar
                    // breakPoint="sm"
                    className="relative"
                    backgroundColor="none"
                    collapsed={collapsed}
                    rootStyles={{
                        // background: "rgba(38, 37, 37, 0.7) ",

                        // backgroundColor: "rgba(38, 37, 37, 0.7) ",
                        height: "100%",

                        borderRightWidth: "0px",
                    }}
                >
                    <Menu
                        // className="pt-3 bg-transparent"
                        menuItemStyles={{
                            [`&.hover`]: {
                                backgroundColor: "#fff",
                                color: "black !important ",
                            },
                            background: "none",
                            button: {
                                // the active class will be added automatically by react router
                                // so we can use it to style the active menu item
                                [`&.hover`]: {
                                    backgroundColor: "#fff",
                                    color: "black !important ",
                                },
                                [`&.active`]: {
                                    backgroundColor: "#13395e",
                                    color: "#b6c8d9",
                                },
                            },
                        }}
                    >
                        {collapsed ? (
                            <button
                                className="flex justify-end items-center mt-5 ml-10"
                                // icon={<FiChevronsRight />}
                                // icon={
                                //   <FontAwesomeIcon
                                //     // icon="fa-solid fa-square-poll-vertical"
                                //     icon={faChevronRight}
                                //     color="white"
                                //   />
                                // }
                                onClick={handleCollapsedChange}
                            >
                                {" "}
                                <FontAwesomeIcon
                                    // icon="fa-solid fa-square-poll-vertical"
                                    icon={faChevronRight}
                                    color="white"
                                />
                            </button>
                        ) : (
                            <div className="flex ">
                                <div
                                    style={{
                                        padding: "9px",
                                        // textTransform: "uppercase",
                                        fontWeight: "bold",
                                        fontSize: 14,
                                        letterSpacing: "1px",
                                    }}
                                >
                                    <img
                                        src="/styles/images/logo.png"
                                        alt="logo"
                                        className=" h-32 w-40 "
                                    />
                                </div>
                                <button
                                    // suffix={<FiChevronsLeft />}
                                    onClick={handleCollapsedChange}
                                >
                                    <FontAwesomeIcon
                                        // icon="fa-solid fa-square-poll-vertical"
                                        icon={faChevronLeft}
                                        color="white"
                                    />
                                </button>
                            </div>
                        )}
                        {/* <div className="flex justify-center items-center">
              <img
                src="/styles/images/logo.png"
                alt="logo"
                className=" h-16 w-20 "
              />

            </div> */}

                        <MenuItem
                            component={<Link to="/" />}
                            icon={
                                <FontAwesomeIcon
                                    // icon="fa-solid fa-square-poll-vertical"
                                    icon={faSquarePollVertical}
                                    color=""
                                />
                            }
                        >
                            {" "}
                            Dashboard
                        </MenuItem>
                        <MenuItem
                            component={<Link to="/mint" />}
                            icon={<FontAwesomeIcon icon={faSquare} color="" />}
                        >
                            {" "}
                            MINT cceNFT
                        </MenuItem>
                        <MenuItem
                        component={<Link to="/auth" />}
                            icon={<FontAwesomeIcon icon={faGem} color="" />}>
                            {" "}
                            Login with cceNFT
                        </MenuItem>
                        <MenuItem
                            component={<Link to="/register" />} icon={<FontAwesomeIcon icon={faHeart} color="" />}>
                            {" "}
                            Register with cceNFT
                        </MenuItem>
                        <MenuItem
                            component={<Link to="/coming-soon" />} icon={<FontAwesomeIcon icon={faWallet} color="" />}>
                            {" "}
                            WALLETS
                        </MenuItem>
                        <MenuItem
                            component={<Link to="/active-bids" />} icon={<FontAwesomeIcon icon={faBookBookmark} color="" />}>
                            {" "}
                            MY ACTIVE BIDS
                        </MenuItem>
                        <MenuItem
                            component={<Link to="/portfolio" />} icon={<FontAwesomeIcon icon={faUser} color="" />}>
                            {" "}
                            MY PORTFOLIO
                        </MenuItem>
                        <MenuItem
                            component={<Link to="/settings" />} icon={<FontAwesomeIcon icon={faGear} color="" />}>
                            {" "}
                            SETTING
                        </MenuItem>
                    </Menu>
                    <div
                        className=" w-[200px] h-[195px] mt-10 flex flex-col justify-center items-center "
                        style={{
                            borderRadius: "3px",
                            background:
                                "linear-gradient(97deg, #26134F 3.86%, #B43B78 80.87%)",
                        }}
                    >
                        <p className="text-center text-xl px-6 mb-1 font-medium">
                            Enter your mail to get product update.
                        </p>
                        <input
                            type="email"
                            className="w-40 px-2 bg-black text-white  text-sm py-1 rounded-sm"
                            placeholder="Enter your email here"
                        />
                    </div>
                </Sidebar>
                <main className="grow h-full w-full">
                    {/* <button
            className="sb-button"
            onClick={() => setCollapsed(!collapsed)}
          >
            Collapse
          </button> */}
                    <TopBar email={userData.email} name={userData.name} />
                    {children}
                </main>
            </div>
        </div>
    );
}

export default Dashboard;