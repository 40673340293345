import React, {useEffect, useState} from "react";
import md5 from "md5";
import {useParams} from "react-router";
import {toast} from "react-toastify";

function BidHistory(props) {
    const SERVER = window.location.href.includes("localhost") ? "http://localhost:9004":"https://ccnft.lokdon.com";
    const {id} = useParams();
    const [name, setName]=useState("");
    const [avatar, setAvatar]=useState("https://i.ibb.co/rxcmjCw/3.webp");
    const [bidHistory, setBidHistory]=useState([]);
    useEffect(()=>{
        window.main();
        toast.configure();
        if(localStorage.getItem("name") && localStorage.getItem("email")){
            setName(decrypt(localStorage.getItem("name")));
            let avatar="https://www.gravatar.com/avatar/" + md5(decrypt(localStorage.getItem("email"))) + "?s=80";
            setAvatar(avatar);
        }
    },[]);
    function collectBidHistory(){
        var requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };

        fetch(SERVER+"/api/auction/history/"+id, requestOptions)
            .then(response => response.json())
            .then(result => {
                console.log(result);
                if(result.status==='success'){
                    const data=[];
                    if(result.data.length>0){
                        for (let i=0;i<result.data.length;i++){
                            data.push(result.data[i]);
                        }
                        console.log("data",data);
                        setBidHistory([...data]);
                    }else{
                        console.log("no data");
                    }
                }

            })
            .catch(error => console.log('error', error));
    }
    useEffect(()=>{
        console.log("ID: ",id);
        collectBidHistory();
    },[id]);
    useEffect(()=>{
        console.log("bid history",bidHistory);
    },[bidHistory]);

    function decrypt(val){
        return window.main.api.decrypt(val);
    }

    function acceptBid(e) {

    }

    return (
        <div
            className="h-full   w-full flex object-cover bg-cover bg-center rounded-lg"
            style={{
                backgroundImage: 'url("../assets/images/bg_settings.png")',
            }}
        >
            <div className=" flex flex-col h-[90vh] grow justify-between w-[18%] border-r">
                <div className="flex justify-center items-center lg:text-xl md:text-sm text-xs font-semibold uppercase mt-10">
                    Bid History
                </div>
                <div className="flex lg:flex-row md:flex-col flex-col  mx-auto items-center mb-10">
                    <div className="relative z-10 block w-8 h-8 mr-2 overflow-hidden rounded-lg shadow focus:outline-none">
                        <img
                            className="object-cover w-full h-full"
                            src={avatar}
                            alt="Your avatar"
                        />
                    </div>

                    <div className="md:text-sm text-xs mx-2">
                        <div>{name}</div>
                        <div>TRADE ACCOUNT</div>
                    </div>
                </div>
            </div>
            <div className=" lg:w-[80%] w-[70%] flex flex-col h-[90vh]">
                {
                    bidHistory.length > 0 ?
                    <table className="table table-responsive p-4 mt-5">
                        <thead>
                        <tr>
                            <th>Bidder Name</th>
                            <th>Amount (ETH)</th>
                            <th>Escrow (ETH)</th>
                            <th>Actions</th>
                        </tr>
                        </thead>
                        <tbody className="p-2 ml-5">
                        {
                            bidHistory.map((value, index) => {
                                console.log("value: ", value);
                                return (
                                    <tr key={index} className="p-2 ml-4">
                                        <td>{value.bidderName}</td>
                                        <td>{value.amount}</td>
                                        <td>{value.paymentDone ? value.amount : "Pending"}</td>
                                        <td>
                                            <button onClick={acceptBid} className="bg-primary hover:bg-blue-700 text-black-50 font-bold py-2 px-4 rounded">{value.accepted ? "Winner Bid" : "Accept"}</button>
                                        </td>
                                    </tr>
                                )
                            })
                        }
                        </tbody>
                    </table>: <p>No bids submitted for this auction yet</p>
                }
            </div>
        </div>
    );
}

export default BidHistory;
