import {Network, Alchemy, BigNumber} from "alchemy-sdk";
import {pinJSONToIPFS} from "./pinata";
import {ethers} from "ethers";
//import contractABINew from "../contract-abi.json";
// backward compatibility
const alchemyKey = process.env.REACT_APP_ALCHEMY_KEY;
const {createAlchemyWeb3} = require("@alch/alchemy-web3");
const web3 = createAlchemyWeb3(alchemyKey);

const contractABI = require('../contract-abi.json')
const contractAddress = "0x5dA244D5053459b2F4c868B7e4c6F97536D27915";// created new contract on 10/23/2023 for bidding functionality

export const ESCROW_ADDRESS="0x697282d6c50263e061df6f76221b3a22b660b843";
export const LOKDON_ADDRESS="0x697282d6c50263e061df6f76221b3a22b660b843";

// end of backward compatibility
require('dotenv').config();
const settings={
    apiKey: process.env.REACT_APP_ALCHEMY_API_KEY,
    network: Network.ETH_GOERLI
};
const alchemy=new Alchemy(settings);

export const connectWallet=async ()=>{
    if(window.ethereum){
        try{
            const addressArray = await window.ethereum.request({
                method: "eth_requestAccounts",
            });
            const obj = {
                status: "Make sure to prepare the asset first",
                address: addressArray[0],
            };
            return obj;
        }catch(err){
            return {
                address: "",
                status: ""+err.message,
            }
        }
    }else {
        return {
            address: "",
            status: (
                <span>
            <p>
              {" "}
                🦊{" "}
                <a target="_blank" href={`https://metamask.io/download.html`}>
                You must install Metamask, a virtual Ethereum wallet, in your
                browser.
              </a>
            </p>
          </span>
            ),
        };
    }
};
export const getListOfNFTs=async (address)=>{
        return alchemy.nft.getNftsForOwner(address);
};
export const getContractNFTs=async ()=>{
    return alchemy.nft.getNftsForContract(contractAddress);
};
export const getCurrentWalletConnected = async () => {
    if (window.ethereum) {
        try {
            const addressArray = await window.ethereum.request({
                method: "eth_accounts",
            });
            if (addressArray.length > 0) {
                console.log("Wallet CONNECTED");
                return {
                    address: addressArray[0],
                    status: "Make sure to prepare the asset first",
                };
            } else {
                return {
                    address: "",
                    status: "🦊 Connect to Metamask using the top right button.",
                };
            }
        } catch (err) {
            return {
                address: "",
                status: "😥 " + err.message,
            };
        }
    } else {
        return {
            address: "",
            status: (
                <span>
            <p>
              {" "}
                🦊{" "}
                <a target="_blank" href={`https://metamask.io/download.html`}>
                You must install Metamask, a virtual Ethereum wallet, in your
                browser.
              </a>
            </p>
          </span>
            ),
        };
    }
};
export const mintNFT = async (url, name, description) => {
    if (url.trim() == "" || (name.trim() == "" || description.trim() == "")) {
        return {
            success: false,
            status: "❗Please make sure all fields are completed before minting.",
        }
    }
    const metadata = new Object();
    metadata.name = name;
    metadata.image = url;
    metadata.description = description;

    //make pinata call
    const pinataResponse = await pinJSONToIPFS(metadata);
    if (!pinataResponse.success) {
        return {
            success: false,
            status: "😢 Something went wrong while uploading your tokenURI.",
        }
    }
    const tokenURI = pinataResponse.pinataUrl;
    window.contract = await new web3.eth.Contract(contractABI, contractAddress);
    //set up your Ethereum transaction
    const transactionParameters = {
        to: contractAddress, // Required except during contract publications.
        from: window.ethereum.selectedAddress, // must match user's active address.
        data: window.contract.methods
            .mintNFT(window.ethereum.selectedAddress, tokenURI)
            .encodeABI() //make call to NFT smart contract
    };

//sign transaction via Metamask
    try {
        const txHash = await window.ethereum
            .request({
                method: 'eth_sendTransaction',
                params: [transactionParameters],
            });
        return {
            success: true,
            status: "✅ Check out your transaction on Etherscan: https://etherscan.io/tx/" + txHash
        }
    } catch (error) {
        return {
            success: false,
            status: "😥 Something went wrong: " + error.message
        }
    }
}

// digital signatures for cceNFT
export const requestSignature = async (account,message) => {
    try {
        // Check if the wallet exists
        if (account) {
            // Get the user's accounts
            //const accounts = await requestAccounts();
            //const account = accounts[0];

            // Request the user to sign the message using their wallet
            const sig = await window.ethereum.request({
                method: "personal_sign",
                params: [message, account],
            });

            // Set the state variables with the signature and address
            //setSignature(sig);
            //setAddress(account);
            console.log("signature=",sig);
            return sig;
        } else {
            alert("No wallet detected");
        }
    } catch (error) {
        console.error(error);
        throw error;
    }
};

// A function that verifies the signature using the Web3 API
const verifySignature = async (address,signature) => {
    try {
        // Recover the address from the signature and message using the Web3 API
        var message="{name='927394729347927394723947'}";
        const recoveredAddress = await window.ethereum.request({
            method: "personal_ecRecover",
            params: [message, signature],
        });

        // Check if the recovered address matches the original address
        if (recoveredAddress.toLowerCase() === address.toLowerCase()) {
            return true;
        } else {
            return false;
        }
    } catch (error) {
        console.error(error);
        throw error;
    }
};

// new contract functions for bidding
export const createBid = async (tokenId, amount, deadline) => {
    // Check if the parameters are valid
    if (tokenId < 0 || amount <= 0 || deadline <= Date.now()) {
        return {
            success: false,
            status: "❗Please make sure the parameters are valid before creating a bid.",
        };
    }
    const amnt=web3.utils.toWei(amount, "ether");
    console.log(amnt);
    window.contract = await new web3.eth.Contract(contractABI, contractAddress);
    // Set up your Ethereum transaction
    const transactionParameters = {
        to: contractAddress, // Required except during contract publications.
        from: window.ethereum.selectedAddress, // Must match user's active address.
        value: amnt, // The amount of ether or tokens to bid
        data: window.contract.methods
            .createBid(tokenId, amnt, deadline)
            .encodeABI(), // Make call to NFT smart contract
    };

    // Sign transaction via Metamask
    try {
        const txHash = await window.ethereum.request({
            method: "eth_sendTransaction",
            params: [transactionParameters],
        });
        return {
            success: true,
            status:
                "✅ Check out your transaction on Etherscan: https://etherscan.io/tx/" +
                txHash,
        };
    } catch (error) {
        return {
            success: false,
            status: "😥 Something went wrong: " + error.message,
        };
    }
};
export const cancelBid = async (tokenId) => {
    // Check if the token ID is valid
    if (tokenId < 0) {
        return {
            success: false,
            status: "❗Please make sure the token ID is valid before canceling a bid.",
        };
    }
    window.contract = await new web3.eth.Contract(contractABI, contractAddress);
    // Set up your Ethereum transaction
    const transactionParameters = {
        to: contractAddress, // Required except during contract publications.
        from: window.ethereum.selectedAddress, // Must match user's active address.
        data: window.contract.methods.cancelBid(tokenId).encodeABI(), // Make call to NFT smart contract
    };

    // Sign transaction via Metamask
    try {
        const txHash = await window.ethereum.request({
            method: "eth_sendTransaction",
            params: [transactionParameters],
        });
        return {
            success: true,
            status:
                "✅ Check out your transaction on Etherscan: https://etherscan.io/tx/" +
                txHash,
        };
    } catch (error) {
        return {
            success: false,
            status: "😥 Something went wrong: " + error.message,
        };
    }
};
export const acceptBid = async (tokenId) => {
    // Check if the token ID is valid
    if (tokenId < 0) {
        return {
            success: false,
            status:
                "❗Please make sure the token ID is valid before accepting a bid.",
        };
    }
    window.contract = await new web3.eth.Contract(contractABI, contractAddress);
    // Set up your Ethereum transaction
    const transactionParameters = {
        to: contractAddress, // Required except during contract publications.
        from: window.ethereum.selectedAddress, // Must match user's active address.
        data: window.contract.methods.acceptBid(tokenId).encodeABI(), // Make call to NFT smart contract
    };

    // Sign transaction via Metamask
    try {
        const txHash = await window.ethereum.request({
            method: "eth_sendTransaction",
            params: [transactionParameters],
        });
        return {
            success: true,
            status:
                "✅ Check out your transaction on Etherscan: https://etherscan.io/tx/" +
                txHash,
        };
    } catch (error) {
        return {
            success: false,
            status: "😥 Something went wrong: " + error.message,
        };
    }
};
// Define a function that gets the NFT bid status for a given token ID
export async function getBidStatus(tokenId) {
    // Check if the token ID is valid
    if (tokenId < 0) {
        return {
            success: false,
            status: "❗Please make sure the token ID is valid before getting the bid status.",
        };
    }
    window.contract = await new web3.eth.Contract(contractABI, contractAddress);
    // Call the bids mapping getter function with the token ID as an argument
    const bid = await window.contract.methods.bids(tokenId).call();
    // Check if there is a bid for the token ID
    if (bid.bidder == "0x0000000000000000000000000000000000000000") {
        return {
            success: true,
            status: "🚫There is no bid for this NFT.",
        };
    } else {
        // Get the bid details from the bid object
        const bidder = bid.bidder;
        const amount = web3.utils.fromWei(bid.amount.toString(), "ether");
        const deadline = new Date(bid.deadline * 1000).toLocaleString();
        return {
            success: true,
            status: `👍There is a bid for this NFT from ${bidder} with ${amount} ether and a deadline of ${deadline}.`,
        };
    }
}

export async function sendETH(amount, address){
    if (window.ethereum.isConnected()) {
        // Set up the transaction parameters
        const transactionParameters = {
            to: address, // The address to send the ETH to
            from: window.ethereum.selectedAddress, // The user's active address
            value: amount, // The amount of ETH to send in wei
        };

        // Sign and send the transaction via MetaMask
        window.ethereum
            .request({
                method: "eth_sendTransaction",
                params: [transactionParameters],
            })
            .then((txHash) => {
                // Display a success message with the transaction hash
                alert(
                    "You have successfully sent 0.01 ETH to the address. You can check your transaction on Etherscan: https://etherscan.io/tx/" +
                    txHash
                );
            })
            .catch((error) => {
                // Display an error message
                alert("Something went wrong: " + error.message);
            });
    } else {
        // Display a warning message
        alert("Please connect to MetaMask and make sure you are on the mainnet.");
    }
}
export async function sendTestEth(amount, address,account){
        // Check if the user is connected to Metamask and has enough balance
        if (account) {
            // Set up the transaction parameters
            const transactionParameters = {
                to: ESCROW_ADDRESS, // The escrow address
                from: account, // The user's account
                value: amount, // The amount to send in wei
            };

            // Sign and send the transaction via Metamask
            try {
                return window.ethereum.request({
                    method: "eth_sendTransaction",
                    params: [transactionParameters],
                });
            } catch (error) {
                // Display an error message
                alert("Something went wrong: " + error.message);
            }
        } else {
            // Display a warning message
            alert("Please connect to Metamask and make sure you have enough Goerli ETH in your account.");
        }
}
export function ethToWei(eth){
    return "0x" + web3.utils.toBN(web3.utils.toWei(eth, "ether").toString()).toString(16);
}

