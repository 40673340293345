import { useEffect, useRef, useState } from "react";
import { connectWallet, getCurrentWalletConnected, mintNFT } from "./utils/interact.js";
import $ from 'jquery';
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckSquare } from "@fortawesome/free-solid-svg-icons";
import { faWarning } from "@fortawesome/free-solid-svg-icons";
import './scss/styles.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {redirect, useNavigate} from "react-router";
import { isMobile } from "react-device-detect";
import wallet from './images/add_wallet.svg'
import eye from './images/show_icon.svg'
import eye_off from './images/hide_icon.svg';
import spatral from './images/spectral line.png'
import Modal from 'react-modal';

require('dotenv').config();

const Signin = (props) => {

    //State variables
    const [walletAddress, setWallet] = useState("");
    const [status, setStatus] = useState("");
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [url, setUrl] = useState("");
    let [mintDisabled] = useState(true);
    let [assetUrl] = useState("");
    let [doneWalletStyle] = useState(false);
    const [imageSelected, setImageSelected] = useState(false);
    const [btn] = useState(['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15']);
    const [images, setImages] = useState(["/assets/images/1.webp", "/assets/images/2.webp", "/assets/images/3.webp", "/assets/images/4.webp", "/assets/images/5.webp", "/assets/images/6.webp", "/assets/images/7.webp", "/assets/images/8.webp", "/assets/images/9.webp", "/assets/images/10.webp", "/assets/images/11.webp", "/assets/images/12.webp", "/assets/images/13.webp", "/assets/images/14.webp", "/assets/images/15.webp", "/assets/images/16.webp"]);
    const [selectedIndex, setSelectedIndex] = useState([]);
    const [statusText, setStatusText] = useState("Choose any 4 NFT at the minimum from the selections below");
    const [isRegister, setRegister] = useState(true);
    const [authSelection, setAuthSelection] = useState([]);
    const [BASE_URL] = useState("https://activation.lokdon.com/stegano");
    const [highlightOff, hideHighlights] = useState(true);
    const {navigate}=useNavigate();
    const [modalIsOpen,setIsOpen]=useState(false);
    const [isReset,setIsReset]=useState(false);


    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
        },
    };
    function addWalletListener() {
        if (window.ethereum) {
            window.ethereum.on("accountsChanged", (accounts) => {
                if (accounts.length > 0) {
                    setWallet(accounts[0]);
                    setStatus("Make sure to prepare the asset");
                    checkRegistration();
                } else {
                    setWallet("");
                    setStatus("🦊 Connect to Metamask using the top right button.");
                }
            });
        } else {
            showErr("You need to install metamask wallet");
            if (isMobile) {
                showErr("If you\'re on mobile, please install the smsLocker mobile app for your platform instead.");
            }
            setStatus(
                <p>
                    {" "}
                    🦊{" "}
                    <a target="_blank" href={`https://metamask.io/download.html`}>
                        You must install Metamask, a virtual Ethereum wallet, in your
                        browser.
                    </a>
                </p>
            );
        }
    }


    function setExtra() {
        if (navigator.geolocation === undefined || !navigator.geolocation.hasOwnProperty('getCurrentPosition'))
            return;
        navigator.geolocation.getCurrentPosition(function (position) {
            console.log("Latitude is :", position.coords.latitude);
            console.log("Longitude is :", position.coords.longitude);
            let t = position.timestamp;
            $("#timestamp").val(t);
            $("#location").val(position.coords.latitude + ":" + position.coords.longitude);
        });
    }
    function imageClicked(event) {
        const id = event.currentTarget.id;
        if (!highlightOff)
            event.currentTarget.classList.toggle("selected");
        console.log("image id is: " + id);
        let a = images[id.substring(4)];
        if (isRegister) {
            if (selectedIndex.includes(a))
                selectedIndex.splice(selectedIndex.indexOf(a), 1);
            else {
                selectedIndex.push(a);
            }
        } else {
            if (authSelection.includes(a))
                authSelection.splice(authSelection.indexOf(a), 1);
            else authSelection.push(a);
        }
    }
    useEffect(async () => {
        const { address, status } = await getCurrentWalletConnected();
        setWallet(address)
        setStatus(status);
        console.log("wallet addr: " + address);
        toast.configure();
        addWalletListener();
        setExtra();
        shuffleItems();
        if (address.length > 0) {
            console.log('wallet address: ', address);
            checkRegistration(address);
        }
    }, []);
    useEffect(() => {
        if (walletAddress.length > 0) {
            console.log("wallet address change", walletAddress);
            checkRegistration(walletAddress);
        } else {
            console.log('User wallet either disconnected or errors with session', walletAddress);
        }
    }, [walletAddress]);

    const connectWalletPressed = async () => {
        const walletResponse = await connectWallet();
        setStatus(walletResponse.status);
        setWallet(walletResponse.address);
    };

    function shuffleItems() {
        let array = [...images];
        let currentIndex = array.length, randomIndex;

        // While there remain elements to shuffle.
        while (currentIndex !== 0) {

            // Pick a remaining element.
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex--;

            // And swap it with the current element.
            [array[currentIndex], array[randomIndex]] = [
                array[randomIndex], array[currentIndex]];
        }
        setImages(array);
        clearSelection();
        console.log("shuffling");
    }

    function showErr(msg) {

        toast(msg, {
            type: "error",
        });
    }
    function showInfo(msg) {
        toast(msg, {
            type: "success"
        });
    }

    function clearSelection() {
        for (let i = 0; i < btn.length; i++) {
            let b = document.querySelector("#btn_" + btn[i]);
            b.classList.remove("selected");
        }
    }

    function isSelectedBtn(i) {
        if (selectedIndex.includes(images[i]) || authSelection.includes(images[i]))
            return true;
        return false;
    }

    function reapplySelection() {
        for (let i = 0; i < btn.length; i++) {
            if (isSelectedBtn(i)) {
                let b = document.querySelector("#btn_" + btn[i]);
                b.classList.add("selected");
            }
        }
    }

    function proceed() {
        console.log("proceed with " + selectedIndex.length + " images selected");
        //localStorage.clear();
        if (walletAddress.length < 5) {
            showErr("Please connect to Metamask first!");
            return;
        }
        if (isReset) {
            if (selectedIndex.length < 4) {
                showErr("Error: Select at least 4 images");
            } else if (selectedIndex.length > 6) {
                showErr("Error: Max allowed selections is 6");
            } else {
                savePattern();
                /*showInfo("your selection is saved");
                clearSelection();
                shuffleItems();
                setStatusText("Select your NFTs to proceed");
                setRegister(false);*/
            }
        } else {
            console.log("authorizing");
            authorize();
        }
    }
    function savePattern() {
        if (walletAddress.length > 0 && isRegister && selectedIndex.length > 0) {
            let body = {
                address: walletAddress.substring(walletAddress.length - 8),
                hashes: selectedIndex
            }
            fetch(BASE_URL + '/register/update.php', {
                method: "POST",
                body: JSON.stringify(body),
                headers: {
                    'Content-Type': 'application/json',
                }
            }).then((result) => result.json())
                .then((data) => {
                    console.log("registration response", data);
                    if (data.status === 'success') {
                        showInfo(data.message);
                        setTimeout(()=>{
                            window.location.replace("/auth/");
                        },3000);
                    } else {
                        if (data.error === 0) {
                            showErr("You already have saved patter, confirm to login");
                            clearSelection();
                            setStatusText("Confirm your NFT patter to login");
                            setRegister(false);
                        }else{
                            showErr(data.message);
                        }
                    }
                })
                .then((err) => {
                    console.log(err);
                });
        } else {
            showErr("Please satisfy all requirements first!");
        }
    }
    function authorize() {
        if (walletAddress.length > 0 && authSelection.length >= 4) {
            let body = {
                address: walletAddress.substring(walletAddress.length - 8),
                hashes: authSelection
            };
            console.log("auth payload", body);
            fetch(BASE_URL + "/authorize/index.php", {
                method: "POST",
                body: JSON.stringify(body),
                headers: {
                    'Content-Type': 'application/json',
                }
            })
                .then((result) => result.json())
                .then((data) => {
                    console.log("authroization response", data);
                    if (data.status === 'success') {
                        let token=data.token;
                        localStorage.setItem("token",token);
                        localStorage.setItem("email",data.user.email);
                        localStorage.setItem("name",data.user.name);
                        localStorage.setItem("expire",(Date.now()*60*60*15).toString());
                        showInfo(data.message);
                        setTimeout(() => {
                            window.location.href=data.redirect;
                            //window.open(data.redirect, '_blank', 'noreferrer');
                        }, 2000);
                    } else {
                        showErr(data.message);
                        clearSelection();
                    }
                })
                .then((err) => {
                    console.log(err);
                });
        }
    }
    function checkRegistration(addr = '') {
        console.log("checking registration status...");
        console.log("origin", walletAddress);
        console.log("neworigin", addr);
        if (walletAddress.length > 0 || addr.length > 0) {
            if (addr.length === 0)
                addr = walletAddress;
            let body = {
                address: addr.substring(addr.length - 8)
            }
            fetch(BASE_URL + '/hasAuth/index.php', {
                method: "POST",
                body: JSON.stringify(body),
                headers: {
                    'Content-Type': 'application/json',
                }
            })
                .then((response) => response.json())
                .then((data) => {
                    console.log("response", data);
                    if (data.status === 'success') {
                        setRegister(false);
                        showInfo("You already have a saved pattern, verify to proceed");
                        clearSelection();
                        shuffleItems();
                        setStatusText("Select at least 4 NFTs to login");
                    } else {
                        if(data.error===1){
                            showInfo("You can now enter your new selections and click save!");
                            setIsReset(true);
                        }else if(data.error===2){
                            setIsOpen(true);
                        }
                        else
                            window.location.replace("/register");
                        /*
                        clearSelection();
                        shuffleItems();
                        setStatusText("Select your NFTs to proceed");
                        setRegister(true);*/
                    }
                }).catch((err) => {
                    console.log(err);
                    //showErr("Error: unable to connect to smart contract");
                });
        } else {
            console.log("Wallet not connected yet");
        }
    }

    function showHighlight() {
        if (highlightOff) {
            hideHighlights(false);
            showInfo("Your selections might be visible to people around you!");
            reapplySelection();
        } else {
            hideHighlights(true);
            clearSelection();
        }
    }

    function forgot() {
        if(walletAddress.length<4){
            showErr("Please connect your wallet first!");
            return;
        }
        let body={
            address: walletAddress.substring(walletAddress.length - 8),
        };
        fetch(BASE_URL+"/forgot.php",{
           method: "POST",
           body: JSON.stringify(body),
           headers: {
               "Content-Type":"application/json"
           }
        }).then((result)=>result.json())
            .then((response)=>{
               console.log("response",response);
               if(response.status==='success'){
                   showInfo(response.message);
               }else{
                   showErr(response.message);
               }
            }).then((err)=>{
                console.log(err);
        }).catch((reason)=>{
            console.log(reason);
        });
    }

    return (
        <main className="grid lg:grid-cols-2 grid-cols-1 ">
            <div className='hidden lg:flex bg-[url("assets/ccNFT/bg_image.jpg")] object-cover h-full w-full bg-cover bg-center p-12 '>
                {/* <img src="assets/ccNFT/bg_image.jpg" alt="side background image" className=" h-screen w-screen" /> */}
                <div class="flex align-center justify-center mb-auto bg-black opacity-60 p-4 " >
                    <h1 class="text-white font-semibold text-3xl text-center p-5  ">ECSMID color-coded encrypted non-fungible Token
                        (cceNFT)</h1>
                </div>
            </div>
            <div className="h-full w-full" style={{ background: "linear-gradient(311.9deg, #F8F8F8 3.16%, #DBDBDB 75.05%)" }}>

                <div className="flex justify-end py-4 px-5 space-x-4  ">
                    <span><img src={highlightOff ? eye_off : eye} alt="eye icon" className=" h-10 w-10 cursor-pointer " onClick={showHighlight} /></span>
                    <button onClick={connectWalletPressed}>{walletAddress.length > 0 ? (
                        <span className=" text-slate-900  ">{"Connected: " +
                            String(walletAddress).substring(0, 6) +
                            "..." +
                            String(walletAddress).substring(38)}</span>
                    ) : (
                        // <span>Connect Wallet</span>
                        <span><img src={wallet} alt="wallet" className="h-10 w-10" /></span>
                    )}</button>
                    <img src="assets/ccNFT/svg_icon_shuffle.png" alt="" className=" h-10 w-10 cursor-pointer " onClick={shuffleItems} />


                </div>
                <h1 style={{ color: "#044470" }} className=" uppercase text-center font-bold text-xl">Password Enhancement System.</h1>
                <header style={{ color: "#044470" }} className="pt-1 text-center text-xl">{statusText}
                </header>
                <section className="px-1 lg:px-12 mx-7 lg:mt-16 mt-20 " >
                    <div className=" " style={{ border: "1px solid #751A47", borderRadius: "20px" }}>
                        <div >
                            <div className="calculator-grid grid grid-cols-4 bg-white  mx-10 my-5 rounded-3xl  shadow-2xl  ">
                                <button onClick={imageClicked} id={"btn_" + btn[0]} data-number="" style={{ border: "1px solid rgba(4, 68, 112, 0.2)", borderTopLeftRadius: "20px" }} ><img src={images[0]} alt=""
                                    className=" h-14 w-14 lg:h-20 lg:w-20 mx-auto " /></button>
                                <button onClick={imageClicked} id={"btn_" + btn[1]} data-number="" style={{ border: "1px solid rgba(4, 68, 112, 0.2)" }}><img src={images[1]} alt=""
                                    className=" h-14 w-14 lg:h-20 lg:w-20 mx-auto" /></button>
                                <button onClick={imageClicked} id={"btn_" + btn[2]} data-number="" style={{ border: "1px solid rgba(4, 68, 112, 0.2)" }}><img src={images[2]} alt=""
                                    className=" h-14 w-14  lg:h-20 lg:w-20 mx-auto" /></button>
                                <button onClick={imageClicked} id={"btn_" + btn[3]} data-operation="" style={{ border: "1px solid rgba(4, 68, 112, 0.2)", borderTopRightRadius: "20px" }}><img src={images[3]} alt=""
                                    className=" h-14 w-14 lg:h-20 lg:w-20 mx-auto" /></button>
                                <button onClick={imageClicked} id={"btn_" + btn[4]} data-number="" style={{ border: "1px solid rgba(4, 68, 112, 0.2)" }}><img src={images[4]} alt=""
                                    className=" h-14 w-14 lg:h-20 lg:w-20 mx-auto" /></button>
                                <button onClick={imageClicked} id={"btn_" + btn[5]} data-number="" style={{ border: "1px solid rgba(4, 68, 112, 0.2)" }}><img src={images[5]} alt=""
                                    className=" h-14 w-14 lg:h-20 lg:w-20 mx-auto" /></button>
                                <button onClick={imageClicked} id={"btn_" + btn[6]} data-number="" style={{ border: "1px solid rgba(4, 68, 112, 0.2)" }}><img src={images[6]} alt=""
                                    className=" h-14 w-14 lg:h-20 lg:w-20 mx-auto" /></button>
                                <button onClick={imageClicked} id={"btn_" + btn[7]} data-operation="" style={{ border: "1px solid rgba(4, 68, 112, 0.2)" }}><img src={images[7]} alt=""
                                    className=" h-14 w-14 lg:h-20 lg:w-20 mx-auto" /></button>
                                <button onClick={imageClicked} id={"btn_" + btn[8]} data-number="" style={{ border: "1px solid rgba(4, 68, 112, 0.2)" }}><img src={images[8]} alt=""
                                    className=" h-14 w-14 lg:h-20 lg:w-20 mx-auto" /></button>
                                <button onClick={imageClicked} id={"btn_" + btn[9]} data-number="" style={{ border: "1px solid rgba(4, 68, 112, 0.2)" }}><img src={images[9]} alt=""
                                    className=" h-14 w-14 lg:h-20 lg:w-20 mx-auto" /></button>
                                <button onClick={imageClicked} id={"btn_" + btn[10]} data-number="" style={{ border: "1px solid rgba(4, 68, 112, 0.2)" }}><img src={images[10]} alt=""
                                    className=" h-14 w-14 lg:h-20 lg:w-20 mx-auto" /></button>
                                <button onClick={imageClicked} id={"btn_" + btn[11]} data-operation="" style={{ border: "1px solid rgba(4, 68, 112, 0.2)" }}><img src={images[11]} alt=""
                                    className=" h-14 w-14 lg:h-20 lg:w-20 mx-auto" /></button>
                                <button onClick={imageClicked} id={"btn_" + btn[12]} data-number="" style={{ border: "1px solid rgba(4, 68, 112, 0.2)", borderBottomLeftRadius: "20px" }}><img src={images[12]} alt=""
                                    className=" h-14 w-14 lg:h-20 lg:w-20 mx-auto" /></button>
                                <button onClick={imageClicked} id={"btn_" + btn[13]} data-number="" style={{ border: "1px solid rgba(4, 68, 112, 0.2)" }}><img src={images[13]} alt=""
                                    className=" h-14 w-14 lg:h-20 lg:w-20 mx-auto" /></button>
                                <button onClick={imageClicked} id={"btn_" + btn[14]} data-number="" style={{ border: "1px solid rgba(4, 68, 112, 0.2)" }}><img src={images[14]} alt=""
                                    className=" h-14 w-14 lg:h-20 lg:w-20 mx-auto" /></button>
                                <button onClick={imageClicked} id={"btn_" + btn[15]} data-operation="" style={{ border: "1px solid rgba(4, 68, 112, 0.2)", borderBottomRightRadius: "20px" }}><img src={images[15]} alt=""
                                    className=" h-14 w-14 lg:h-20 lg:w-20 mx-auto" /></button>
                            </div>
                        </div>
                    </div>

                </section>
                <div className="flex justify-center mx-4 my-8">

                    <img src={spatral} alt='' />
                </div>

                <div className="flex justify-center m-4">
                    <a onClick={forgot}   className="py-2 px-8 rounded text-white  font-extrabold" style={{
                        background: "linear-gradient(180deg, #ff0000 22.09%, #46102B 132.56%)",
                        boxShadow: "0px 0px 6px 1px rgba(0, 0, 0, 0.25)",
                        marginRight: "50px",
                    }}>Forgot your selections?</a>
                    <button onClick={proceed} className="py-2 px-8 rounded text-white  font-extrabold" style={{
                        background: "linear-gradient(180deg, #7C1946 22.09%, #46102B 132.56%)",
                        boxShadow: "0px 0px 6px 1px rgba(0, 0, 0, 0.25)"
                    }}>
                        {isReset ? "Update Selections" : "Authorize"}
                    </button>
                </div>
            </div>
            <Modal
                isOpen={modalIsOpen}
                style={customStyles}
                contentLabel="Verification Required"
            >
                <h2 className="text-black-50">Check your email for verification link</h2>
                <p className="text-black-50">We sent a verification link to your email, please check both your inbox and spam folder and follow the instructions accordingly.</p>
            </Modal>
        </main>
    );
};

export default Signin;
