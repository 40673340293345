import {useEffect, useState} from "react";
import {toast} from "react-toastify";

const Pitchup=(props)=>{
    const [userData,setData]=useState({
        email: {
            value: "",
            error: ""
        },
        full_name: {
            value: "",
            error: ""
        },
        company_overview: {
            value: "",
            error: ""
        },
        problem: {
            value: "",
            error: ""
        },
        potential: {
            value: "",
            error: ""
        },
        competitors: {
            value: "",
            error: ""
        },
        vision: {
            value: "",
            error: ""
        },
        prototype_link: {
            value: "",
            error: ""
        },
        join_discord: {
            value: false,
            error: ""
        },
        discord_id: {
            value: "",
            error: ""
        },
        attachments: {
            value: "",
            error: ""
        }
    });
    const [isValidated,setIsValid]=useState(false);
    const [token,setToken]=useState("");
    const [filled, setFilled]=useState(false);
    const [endpoint]=useState("https://activation.lokdon.com/partners");
    const hideStyle={
        display: "none"
    };
    const showStyle={
        display: "block"
    };
    function submitData(e){
        e.preventDefault();
        console.log("submitting data",e);
        const d={
            email: {
                value: e.target[1].value,
                error: ""
            },
            full_name: {
                value: e.target[0].value,
                error: ""
            },
            company_overview: {
                value: e.target[2].value,
                error: ""
            },
            problem: {
                value: e.target[3].value,
                error: ""
            },
            potential: {
                value: e.target[4].value,
                error: ""
            },
            competitors: {
                value: e.target[5].value,
                error: ""
            },
            vision: {
                value: e.target[6].value,
                error: ""
            },
            prototype_link: {
                value: e.target[8].value,
                error: ""
            },
            join_discord: {
                value: e.target[9].checked,
                error: "",
            },
            discord_id: {
                value: e.target[10].value,
                error: ""
            },
            attachments: {
                value: "",
                error: ""
            }
        };
        setData(d);
        /*validate(d);
        console.log("user data is",d);
        if(!isValidated) {
            showErr("Fix issues and try again");
            return;
        }*/
        let body={
            data: d,
            token: token
        };
        let formData=new FormData();
        formData.append("email",d.email.value);
        formData.append("full_name",d.full_name.value);
        formData.append("company_overview",d.company_overview.value);
        formData.append("problem",d.problem.value);
        formData.append("potential",d.potential.value);
        formData.append("competitors",d.competitors.value);
        formData.append("vision",d.competitors.value);
        formData.append("prototype_link",d.prototype_link.value);
        formData.append("join_discord",d.join_discord.value);
        formData.append("discord_id",d.discord_id.value);
        formData.append("attachment",e.target[11].files[0],e.target[11].files[0].name);
        formData.append("token",token);
        console.log('form data: ',formData);
        fetch(endpoint+"/pitchup2.php",{
            method: "POST",
          body: formData
        }).then((response)=>response.json())
            .then((response)=>{
                console.log("response",response);
                if(response.status==='success'){
                    setFilled(true);
                    showInfo("We received your response, thanks for filling the form!");
                }else{
                    showErr(response.message);
                }
            })
            .catch((err)=>{console.log(err);})

    }
    function validate(){
        if(userData.email.value==="" || !userData.email.value.includes("@")){
            userData.email.error="Enter a valid email address";
            showErr(userData.email.error);
            setIsValid(false);
        }else{
            userData.email.error="";
            setIsValid(true);
        }
        if(userData.full_name.value==="" || userData.full_name.value.length<4 || !userData.full_name.value.includes(" ")){
            userData.full_name.error="Enter your full name";
            showErr(userData.full_name.error);
            setIsValid(false);
        }else{
            userData.full_name.error="";
            setIsValid(true);
        }
        if(userData.company_overview.value.length<25){
            userData.company_overview.error="Write a short summary of your company in 100+ characters";
            showErr(userData.company_overview.error);
            setIsValid(false);
        }else{
            userData.company_overview.error="";
            setIsValid(true);
        }
        if(userData.problem.value.length<25){
            userData.problem.error="Write a problem you solved";
            showErr(userData.problem.error);
            setIsValid(false);
        }else{
            userData.problem.error="";
            setIsValid(true);
        }
        if(userData.potential.value.length<25){
            userData.potential.error="Write your potential customers";
            showErr(userData.potential.error);
            setIsValid(false);
        }else{
            userData.potential.error="";
            setIsValid(true);
        }
        if(userData.competitors.value.length<25){
            userData.competitors.error="Enter your competitors list";
            showErr(userData.competitors.error);
            setIsValid(false);
        }else{
            userData.competitors.error="";
            setIsValid(true);
        }
        if(userData.vision.value.length<25){
            userData.vision.error="Write your company vision";
            showErr(userData.vision.error);
            setIsValid(false);
        }else{
            userData.vision.error="";
            setIsValid(true);
        }
    }
    function showErr(msg) {

        toast(msg, {
            type: "error",
        });
    }
    function showInfo(msg) {
        toast(msg, {
            type: "success"
        });
    }

    useEffect(()=>{
        if(localStorage.getItem("token")===null){
            window.location.replace("/auth");
        }else if(localStorage.getItem("expire")===null){
            showErr("Your session expired, please login again");
            setTimeout(()=>{
                window.location.replace("/auth");
            },3000);
        }
        else{
            let expire=parseInt(localStorage.getItem("expire"));
            let time=Date.now();
            if(time<expire) {
                setToken(localStorage.getItem("token"));
                let uData = userData;
                uData.email.value = localStorage.getItem("email");
                uData.full_name = localStorage.getItem("name");
                setData(uData);
            }else{
                showErr("Your session expired, please login again");
                setTimeout(()=>{
                    window.location.replace("/auth");
                },3000);
            }
        }
        if(localStorage.getItem("filled")!=null){
            setFilled(true);
        }
        toast.configure();
    },[]);

    return(

        <div>
            <link rel="stylesheet" href="/styles/css/style2.css" type = "text/css" />
            <h1 style={token.length===0 ? showStyle:hideStyle} >Authentication required!</h1>
            <div style={token.length>0 && !filled ? showStyle:hideStyle}>
                <main>
                <section className="banner">
                    <div className="img-cont">
                        <img src="/assets/images/flier.jpeg" alt=""/>
                    </div>
                </section>

                <section className="form">
                    <form action="#" id="pitchForm" onSubmit={submitData}>
                        <div className="form-intro">
                            <div className="top-bg"></div>
                            <div className="intro-text">
                                <h1>
                                    African Pitch-Up With LOKDON LLC
                                </h1>
                                <div className="sub-intro">
                                    Adopt a global mindset as you pitch*
                                </div>
                            </div>
                            <hr/>
                                <div className="intro-text">
                                    All fields marked with <span className="red">*</span> is required.
                                </div>
                        </div>

                        <div className="discord-link input-cont">
                            <p className="bold">
                                <a href="https://discord.gg/a5T58aTf" target="_blank"
                                   rel="noopener noreferrer">https://discord.gg/a5T58aTf</a>
                            </p>
                            <p className="bold">
                                Join the Discord channel to join the first batch!!!
                            </p>
                        </div>
                        <div className="discord-link input-cont">
                            <label htmlFor="fullname">Full Name <span className="red">*</span></label>
                            <input style={{color: "black"}} style={{color: "black"}} type="text" name="fullname" required="required" id="fullname"
                                   placeholder="Your answer" value={userData.full_name.value} />
                        </div>

                        <div className="discord-link input-cont">
                            <label htmlFor="email">Email Address <span className="red">*</span></label>
                            <input disabled='disabled' style={{color: "black"}} type="email" name="email" required="required" id="email" placeholder="Your answer" value={userData.email.value}/>
                        </div>

                        <div className="discord-link input-cont">
                            <label htmlFor="company">Please provide a company overview <span
                                className="red">*</span></label>
                            <input style={{color: "black"}} type="text" name="company" required="required" id="company"
                                   placeholder="Your answer"/>
                        </div>

                        <div className="discord-link input-cont">
                            <label htmlFor="problem">
                                What "Problem" are you solving or what need are you serving?
                                <span className="red">*</span>
                            </label>
                            <textarea name="problem" required="required" id="problem" placeholder="Your answer"
                                      rows="5"></textarea>
                        </div>
                        <div className="discord-link input-cont">
                            <label htmlFor="product">
                                Who will use your product/service? Where and what is the market size/potential?
                                <span className="red">*</span>
                            </label>
                            <textarea name="product" required="required" id="product" placeholder="Your answer"
                                      rows="5"></textarea>
                        </div>

                        <div className="discord-link input-cont">
                            <label htmlFor="competitors">
                                Who are your competitors(Similar products)?
                                <span className="red">*</span>
                            </label>
                            <textarea name="competitors" required="required" id="competitors" placeholder="Your answer"
                                      rows="5"></textarea>
                        </div>

                        <div className="discord-link input-cont">
                            <label htmlFor="vision">
                                What is the big idea? the vision?
                                <span className="red">*</span>
                            </label>
                            <textarea name="vision" required="required" id="vision" placeholder="Your answer"
                                      rows="5"></textarea>
                        </div>

                        <div className="discord-link input-cont">
                            <label htmlFor="strength">
                                What is your unique selling point?
                                <span className="red">*</span>
                            </label>
                            <textarea name="strength" required="required" id="strength" placeholder="Your answer"
                                      rows="5"></textarea>
                        </div>

                        <div className="discord-link input-cont">
                            <label htmlFor="prototype">Link to prototype if any.</label>
                            <input style={{color: "black"}} type="text" name="prototype" required="required" id="prototype"
                                   placeholder="Your answer"/>
                        </div>
                        <div className="discord-link input-cont">
                            <label htmlFor="discord">
                                <input style={{color: "black"}} type="checkbox" name="discord" id="discord" required="required"/>
                                I agree that I have joined the discord server
                            </label>
                        </div>
                        <div className="discord-link input-cont">
                            <label htmlFor="discord_id">
                                Share your Discord ID for confirmation.
                                <span className="red">*</span>
                            </label>
                            <input style={{color: "black"}} type="text" name="discord_id" required="required" id="discord_id"
                                   placeholder="Your answer"/>
                        </div>
                        <div className="discord-link input-cont">
                            <label htmlFor="pitchfile">
                                Attach your Pitch-deck
                                <span className="red">*</span>
                            </label>
                            <input style={{color: "black"}} type="file" name="pitchFile" required="required" id="pitchFile"
                                   placeholder="Your answer"/>
                        </div>
                        <div className="btns">
                            <input style={{color: "black"}} type="submit" value="Submit"/>
                            <input style={{color: "black"}} type="reset" value="Clear form"/>
                        </div>
                    </form>
                </section>
                </main>
            </div>
            <div style={token.length>0 && filled ? showStyle:hideStyle}>
                <h1>Thanks for filling the form</h1>
            </div>
        </div>
    );
};
export default Pitchup;