import React,{useEffect, useState} from "react";
import Navbar from "./Navbar";
import Sidebar from "./Sidebar";
import TopBar from "../TopBar";

const Layout = ({ children }) => {
    const [collapsed, setCollapsed] = useState(false);
    const [toggled, setToggled] = useState(false);
    const [userData,setUserData]=useState({
        email: "",
        name: ""
    });

    useEffect(()=>{
        window.main();
        //window.addEventListener("storage",onUserDataChange);
        window.localStorageChanged=(data)=>{
            setUserData({...userData, email: data.email, name: data.name});
        };
        if (localStorage.getItem("name") && localStorage.getItem("email")) {
            let name=decrypt(localStorage.getItem("name"));
            let email=decrypt(localStorage.getItem("email"));
            let data=userData;
            data.email=email;
            data.name=name;
            setUserData({...userData, email: data.email, name: data.name});
        }
    },[]);

    const handleCollapsedChange = () => {
        setCollapsed(!collapsed);
    };
    const handleToggleSidebar = (value) => {
        setToggled(value);
    };
    const onUserDataChange=(event)=>{
        console.log("EVENT localStorage",event);
        if(event.key === "email"){
            let data=userData;
            data.email=decrypt(event.newValue);
            setUserData({...userData,email: data.email});
        }
        if(event.key==="name"){
            let data=userData;
            data.name=decrypt(event.newValue);
            setUserData({...userData,name: data.name});
        }
    }
    function encrypt(val) {
        return window.main.api.encrypt(val);
    }

    function decrypt(val) {
        return window.main.api.decrypt(val);
    }
  return (
    <>
      <div className=" flex flex-auto bg-black bg-[url('assets/ccNFT/bg-user.png')] object-cover  min-h-screen min-w-screen bg-cover bg-center  text-white">
        <div
          style={{
            display: "flex",
            height: "100%",
            width: "100%",
            color: "white",
          }}
        >
          <Sidebar
            style={{
              height: "100%",
            }}
          />
          <div className="grow">
            <TopBar name={userData.name} email={userData.email} />
            <div className="m-5">{children}</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Layout;
