import React, {useEffect, useState} from "react";
import {
    connectWallet, ESCROW_ADDRESS, ethToWei,
    getContractNFTs,
    getCurrentWalletConnected,
    getListOfNFTs,
    mintNFT,
    requestSignature, sendTestEth
} from "./utils/api.js";
import Vid from "./images/vid.svg";
import user from "./images/user.png";
import user1 from "./images/user1.png";
import user2 from "./images/user2.png";
import verify from "./images/verify1.png";
import verify1 from "./images/verify2.png";
import verify2 from "./images/verify3.png";
import Modal from "@mui/material/Modal";
import {Backdrop, Box, Typography} from "@mui/material";
import QRCode from "react-qr-code";
import "./utils/ecsmid";
import {toast} from "react-toastify";
import md5 from "md5";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: '#170908',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4
};
function CustomBackdrop(props) {
    // Use the same props as the default backdrop component
    const { open, ...other } = props;

    // Add your custom style and logic here
    return (
        <Backdrop
            open={open}
            {...other}
            // Set the style to make the backdrop opaque and not clickable
            style={{ backgroundColor: "black", opacity: 0.8, pointerEvents: "none" }}
        />
    );
}

const Dashboard = (props) => {
    const [nftList, setNFTList] = useState([]);
    const [bidNFTs, setBidNFTs] = useState([]);
    const [message, setMessage] = useState("Welcome to cceNFT, a decentralized application that allows you to create and share secure NFTs. By signing this request, you are proving your identity and ownership of your wallet address without revealing your private key or password. You are also agreeing to our terms and conditions at https://lokdon.com/legal/ . Signing this message will not make any blockchain transaction or cost you any gas fees or money. It is only for authentication purposes. Please review the message carefully and only sign it if you trust the app and understand what it is asking for.")
    const [signIn, setSignIn] = useState(false);
    const [open, setOpen] = React.useState(false);
    const [walletAddress, setWalletAddress] = useState("");
    const [status, setStatus] = useState("");
    const [signInButton, setSignInButton] = useState("Connect Wallet");
    const [signature, setSignature] = useState("");
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [errorName,setErrorName]=useState("");
    const [errorEmail,setErrorEmail]=useState("");
    const [userData,setUserData]=useState({
        email: "",
        name: ""
    });
    const SERVER = window.location.href.includes("localhost") ? "http://localhost:9004":"https://ccnft.lokdon.com";
    const [showSell, setShowSell]=useState(false);
    const [activeNFT, setActiveNFT]=useState({
        nft: null,
        amount: ""
    });
    const [auctionList, setAuctionList]=useState([]);
    const [bid, setBid]=useState({
        tokenId: "",
        amount: "",
        address: "",
        nickname: ""
    });

    useEffect(async () => {
        console.log("modal is ", open);
        window.main();
        toast.configure();
        if (localStorage.getItem("signature") && localStorage.getItem("email") && localStorage.getItem("timestamp") && localStorage.getItem("version")) {
            setSignature(decrypt(localStorage.getItem("signature")));
            let name=decrypt(localStorage.getItem("name"));
            let email=decrypt(localStorage.getItem("email"));
            let data=userData;
            data.email=email;
            data.name=name;
            setUserData({...userData, email: data.email, name: data.name});
            setSignIn(true);
        }else{
            console.log("not signed in, opening modal");
            localStorage.clear();
            setOpen(!open);
            setSignInButton("Sign In With Connected Wallet");
        }
        setTimeout(() => {
            getCurrentWalletConnected()
                .then((result) => {
                    if (result.address.length > 0) {
                        console.log("wallet connected, modal is", open);
                        setWalletAddress(result.address);
                    } else {

                        if (walletAddress.length === 0) {
                            console.log("Wallet NOT connected, opening modal");
                            setOpen(!open);
                            setSignIn(false);
                            setSignInButton("Connect Wallet");
                        }
                    }
                })
                .catch((err) => {
                    console.log("wallet not connected");
                    setOpen(!open);
                });
        }, 1000);

        addWalletListener();
    }, []);
    useEffect(() => {
        getAuctions();
    }, [walletAddress]);
    useEffect(()=>{
        console.log("Auction list",auctionList);
        if(auctionList.length>0)
            loadFeaturedNFTs();
    }, [auctionList]);


    function encrypt(val) {
        let encrypted=window.main.api.encrypt(val);
        let i=10;
        while(val != decrypt(encrypted) && i>0){
            encrypted=window.main.api.encrypt(val);
            i--;
        }
        return encrypted;
    }

    function decrypt(val) {
        return window.main.api.decrypt(val);
    }
    function updateEmail(event){
        console.log(event);
        setUserData({...userData, email: event.target.value});
        window.localStorageChanged({...userData, email: event.target.value});

    }
    function updateName(event){
        console.log(event);
        setUserData({...userData, name: event.target.value});
        window.localStorageChanged({...userData, name: event.target.value});
    }

    function loadFeaturedNFTs() {
        /*getListOfNFTs("0x07B785Da24a90E010bb2407615B7A173827f8751")
            .then((result)=>{
                console.log("favourite NFTs",result);
            })
            .catch((err)=>console.log("err",err));*/
        getContractNFTs().then((result) => {
            console.log("result", result);
            const ownedNFTs = result.nfts;
            let nftArr = [];
            for(let j=0;j<ownedNFTs.length;j++) {
                for (let i = 0; i < auctionList.length; i++) {
                    if(auctionList[i].tokenId===ownedNFTs[j].tokenId)
                        nftArr.push({
                            nft: ownedNFTs[j].rawMetadata,
                            bid: auctionList[i].basePrice,
                            tokenId: ownedNFTs[j].tokenId
                        });
                }
            }
            console.log("nfts", nftArr);
            setBidNFTs([...nftArr]);
        });
    }

    function signInHandler(event) {
        console.log('connect handler');
        if (walletAddress.length === 0) {
            connectWallet()
                .then((result) => {
                    setStatus(result.status);
                    setWalletAddress(result.address);
                    if (signIn === false) {
                        setSignInButton("Sign In With Connected Wallet");
                    }
                }).catch((err) => console.log(err));
        } else {
            if(userData.email.length===0){
                setErrorEmail("Enter a valid email to proceed!");
                setTimeout(()=>{
                    setErrorEmail("");
                },3000);
                return;
            }
            if(userData.name.length===0){
                setErrorName("Enter a valid nick name to proceed!");
                setTimeout(()=>{
                    setErrorName("");
                },3000);
                return;
            }
            localStorage.setItem("name",encrypt(userData.name));
            localStorage.setItem("email",encrypt(userData.email));
            localStorage.setItem("timestamp",new Date().toUTCString());
            localStorage.setItem("version","1.0.1");
            signatureRequest();
        }
    }

    function signatureRequest() {
        if (walletAddress.length > 0 && signIn === false) {
            let msg = message;
            msg = msg + "\r\n\r\nAccount: " + walletAddress;
            requestSignature(walletAddress, msg)
                .then(async (result) => {
                    setSignature(result);
                    setOpen(!open);
                    localStorage.setItem("signature", encrypt(result));
                    setSignIn(true);
                    console.log("Listing NFTs from OWNER");
                    const nfts = await getListOfNFTs(walletAddress);
                    if (nfts) {
                        const ownedNFTs = nfts.ownedNfts;
                        let nftArr = [...nftList];
                        for (let i = 0; i < ownedNFTs.length; i++) {
                            nftArr.push(ownedNFTs[i].rawMetadata);
                        }
                        console.log("nfts", nftArr);
                        setNFTList([...nftArr]);
                    }

                })
                .catch((err) => console.log("error", err));
        }
    }

    function addWalletListener() {
        if (window.ethereum) {
            window.ethereum.on("accountsChanged", (accounts) => {
                if (accounts.length > 0) {
                    setWalletAddress(accounts[0]);
                    setStatus("Make sure to prepare the asset");
                } else {
                    if (walletAddress.length > 0) {

                    } else {
                        setWalletAddress("");
                        setStatus("🦊 Connect to Metamask using the top right button.");
                        setOpen(!open);
                    }
                }
            });
        } else {
            setStatus(
                <p>
                    {" "}
                    🦊{" "}
                    <a target="_blank" href={`https://metamask.io/download.html`}>
                        You must install Metamask, a virtual Ethereum wallet, in your
                        browser.
                    </a>
                </p>
            );
        }
    }
    function getAuctions(){
        var requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };

        fetch(SERVER+"/api/auction/list", requestOptions)
            .then(response => response.json())
            .then(result => {
                console.log(result);
                if(result.status==='success'){
                    const data=result.data;
                    setAuctionList([...data]);
                }else{

                }
            })
            .catch(error => console.log('error', error));
    }

    function buyNFT(index) {
        setActiveNFT(bidNFTs[index]);
        setBid({...bid,tokenId: bidNFTs[index].tokenId, address: walletAddress, nickname: userData.name, amount: (Number(bidNFTs[index].bid)+0.0001).toLocaleString('en-IN',{maximumSignificantDigits: 6})});
        setShowSell(!showSell);
    }

    function updateNickName(e) {
        setBid({...bid, nickname: e.target.value});
    }

    function updatePrice(e) {
        setBid({...bid, amount: e.target.value});
    }

    function payEscrow(amount, bidId) {
        sendTestEth(ethToWei(amount), "0x92383",walletAddress)
            .then((result)=>{
                if(result===null || result === undefined){
                    let hash=md5(bid.address);
                    showErr("Transaction incomplete, please make sure to send "+bid.amount+" ETH to "+ESCROW_ADDRESS+" and send as this reference number: "+hash+" to support@ccnft.lokdon.com");
                    return;
                }
                console.log("txhash: ",result);
                const req={
                    transactionHash: result,
                    id: bidId,
                    address: walletAddress
                };
                fetch(SERVER+"/api/auction/escrow",{
                    method: "POST",
                    body: JSON.stringify(req),
                    headers: {
                        "Content-Type": "application/json"
                    }
                })
                    .then((result)=>result.json())
                    .then((result)=>{
                        if(result.status==='success'){
                            showInfo(result.message);
                        }else{
                            showErr(result.message);
                        }
                    })
                    .catch((err)=>{
                        console.log("error",err);
                        showErr("Something went wrong, please check your wallet balance and retry the transaction");
                    })
            })
            .catch((err)=>{
                console.log("err",err);
                let hash=md5(bid.address);
                showErr("Transaction incomplete, please make sure to send "+bid.amount+" ETH to "+ESCROW_ADDRESS+" and send as this reference number: "+md5+" to support@ccnft.lokdon.com");
            });
    }

    function submitBid(e) {
        fetch(SERVER+"/api/auction/bid",{
            method: "POST",
            headers: {
                "Content-Type":"application/json"
            },
            body: JSON.stringify(bid)
        })
            .then((result)=>result.json())
            .then((result)=>{
                console.log("response", result);
                if(result.status==='success'){
                    showInfo("Your bid is successfully locked, please proceed with payment to an escrow account. This payment will be refunded to you if your bid is not accepted by the seller");
                    setShowSell(!showSell);
                    payEscrow(bid.amount, result.bid);
                }else if(result.status==='outbid'){
                    showErr(String(result.message));
                    setBid({...bid, amount: (Number(result.minbid)+0.001).toLocaleString('en-IN',{maximumSignificantDigits: 6})});
                }else{
                    showErr(result.message);
                }
            })
            .catch((err)=>console.log("err",err));
    }

    function showErr(msg) {

        toast(msg, {
            type: "error",
        });
    }
    function showInfo(msg) {
        toast(msg, {
            type: "success"
        });
    }

    return (
        <section className=" mt-6 ">
            <div className="flex lg:flex-row flex-col lg:space-x-10 space-x-0 lg:space-y-0 space-y-3  ">
                <div
                    className="h-[300px] lg:w-[65%]  w-[100%] object-cover bg-cover bg-center rounded-lg"
                    style={{
                        backgroundImage:
                            'url("../assets/images/abstract-flowing-neon-wave-background 13.png")',
                    }}
                >
                    <div className="grid md:grid-cols-2 grid-cols-1 gap-3 mt-6">
                        <div className="flex flex-col ml-7 lg:mt-7 mt-2 space-y-4">
                            <div className=" lg:text-xl md:text-sm sm:text-xs font-normal">
                                SECURE DIGITAL ASSETS
                            </div>
                            <div className=" lg:text-3xl md:text-xl text-base font-semibold">
                                WELCOME, TRY THE MOST SECURE NFT
                            </div>
                            <button className=" flex py-2 px-2 md:w-[9rem]  items-center   w-28 rounded-2xl lg:text-sm text-xs text-black bg-[#FFB613]  font-extrabold">
                <span className="mr-1">
                  <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M2.8041 8.353C2.5241 10.956 2.5361 13.958 2.9261 16.55C3.03079 17.2469 3.36698 17.8882 3.88052 18.3707C4.39407 18.8532 5.05508 19.1489 5.7571 19.21L7.2671 19.341C10.4171 19.615 13.5831 19.615 16.7331 19.341L18.2431 19.211C18.9451 19.1499 19.6061 18.8542 20.1197 18.3717C20.6332 17.8892 20.9694 17.2479 21.0741 16.551C21.4641 13.958 21.4761 10.956 21.1961 8.353C21.16 8.05107 21.1193 7.74971 21.0741 7.449C20.9694 6.75215 20.6332 6.11081 20.1197 5.62828C19.6061 5.14575 18.9451 4.85012 18.2431 4.789L16.7331 4.659C13.5837 4.3852 10.4165 4.3852 7.2671 4.659L5.7571 4.789C5.05508 4.85012 4.39407 5.14575 3.88052 5.62828C3.36698 6.11081 3.03079 6.75215 2.9261 7.449C2.88102 7.74972 2.84035 8.05109 2.8041 8.353ZM7.3971 6.153C10.4597 5.88677 13.5395 5.88677 16.6021 6.153L18.1121 6.284C18.4787 6.31601 18.8239 6.47039 19.0922 6.72233C19.3604 6.97427 19.5362 7.30911 19.5911 7.673L19.6251 7.906L14.0641 10.996C13.4327 11.3468 12.7224 11.5308 12.0001 11.5308C11.2778 11.5308 10.5675 11.3468 9.9361 10.996L4.3751 7.906L4.4091 7.673C4.46365 7.30912 4.63913 6.9742 4.90725 6.72222C5.17538 6.47024 5.52054 6.31588 5.8871 6.284L7.3971 6.153ZM19.8081 9.52C20.0025 11.7897 19.9296 14.0743 19.5911 16.327C19.5366 16.6909 19.3611 17.0258 19.0929 17.2778C18.8248 17.5298 18.4797 17.6841 18.1131 17.716L16.6031 17.847C13.5402 18.1132 10.46 18.1132 7.3971 17.847L5.8871 17.716C5.52054 17.6841 5.17538 17.5298 4.90725 17.2778C4.63913 17.0258 4.46365 16.6909 4.4091 16.327C4.07025 14.0744 3.99709 11.7897 4.1911 9.52L9.2071 12.307C10.0613 12.7816 11.0224 13.0306 11.9996 13.0306C12.9768 13.0306 13.9379 12.7816 14.7921 12.307L19.8081 9.52Z"
                        fill="#33240A"
                    />
                  </svg>
                </span>{" "}
                                MINT cceNFT
                            </button>
                        </div>
                        <div className=" md:-ml-20 lg:mt-[0rem] md:mt-[3rem] mt-[-4rem]  ml-10 ">
                            <div className=" lg:w-[25rem] md:w-[20rem] w-[15rem]  ">
                                <img src="/styles/images/BOY_BG_WITH_SHADOW.png" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    style={{backdropFilter: "blur(10px)"}}
                    BackdropComponent={CustomBackdrop}
                >
                    <Box sx={style}>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            Proceed with your web3 wallet
                        </Typography>
                        <Typography id="modal-modal-description" sx={{mt: 2}}>
                            To verify your ownership of assets we need your web3 signature. The signature request
                            doesn't
                            cost any gas fees or doesn't create any transactions. We currently support <a
                            href=""><strong>WalletSMSLockr</strong></a>, <a href=""><strong>MetaMask</strong></a> and <a
                            href=""><strong>Coinbase</strong></a> wallets.
                        </Typography>
                        <div className="grid grid-cols-2 p-2 m-4">
                            <div
                                className="flex-row flex-column align-self-center justify-content-center align-content-center"
                                style={{
                                    background: 'white',
                                    padding: '16px',
                                    alignItems: 'center',
                                    justifyItems: 'center',
                                }}>
                                <QRCode value={"293892933828"} style={{alignSelf: "center", alignItems: "center"}}/>
                            </div>
                            <div className="flex-row align-items-center p-4 m-4">
                                <form style={{display: (walletAddress.length>0 ? "block":"none")}} className="bg-white shadow-md rounded px-4 pt-3 pb-4 mb-2">
                                    <div className="mb-4">
                                        <label className="block text-gray-700 text-sm font-bold mb-2"
                                               htmlFor="email">
                                            Email
                                        </label>
                                        <input
                                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                            id="email" type="email" placeholder="Your Email Address" value={userData.email} onChange={updateEmail}/>
                                        <p className="text-red-500 text-xs italic">{errorEmail}</p>
                                    </div>
                                    <div className="mb-6">
                                        <label className="block text-gray-700 text-sm font-bold mb-2"
                                               htmlFor="name">
                                            Nick Name
                                        </label>
                                        <input
                                            className="shadow appearance-none border border-red-500 rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                                            id="name" type="text" placeholder="Your nick name" value={userData.name} onChange={updateName}/>
                                        <p className="text-red-500 text-xs italic">{errorName}</p>
                                    </div>
                                </form>
                                <button onClick={signInHandler}
                                        className="middle none center mr-2 rounded-lg bg-blue-500 py-3 px-2 font-sans text-l font-bold uppercase text-white shadow-md shadow-blue-500/20 transition-all hover:shadow-lg hover:shadow-blue-500/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none">{signInButton}
                                </button>
                            </div>
                        </div>

                    </Box>
                </Modal>
                <Modal
                    open={showSell}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    style={{backdropFilter: "blur(10px)"}}
                    BackdropComponent={CustomBackdrop}
                >
                    <Box sx={style}>
                        <button onClick={(e)=>{setShowSell(!showSell);}} className="align-self-end ml-0 p-2 red"><i className="btn-close text-red-500">X</i> </button>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            Buying NFT
                        </Typography>
                        <Typography id="modal-modal-description" sx={{mt: 2}}>
                            You are bidding on NFT listed for auction. To increase your chance of winning this auction, you may consider increasing your bid by at least 10% of the leading bid.
                        </Typography>
                        <div className="grid grid-cols-2 p-2 m-4">
                            <div
                                className="flex-row flex-column align-self-center justify-content-center align-content-center"
                                style={{
                                    background: 'transparent',
                                    padding: '16px',
                                    alignItems: 'center',
                                    justifyItems: 'center',
                                }}>
                                <img src={activeNFT.nft !=null ? activeNFT.nft.image:""} style={{alignSelf: "center", alignItems: "center"}}/>
                            </div>
                            <div className="flex-row align-items-center p-4 m-4">
                                <form style={{display: (walletAddress.length > 0 ? "block" : "none")}}
                                      className="bg-white shadow-md rounded px-4 pt-3 pb-4 mb-2">
                                    <div className="mb-4">
                                        <label className="block text-gray-700 text-sm font-bold mb-2"
                                               htmlFor="email">
                                            Your Name
                                        </label>
                                        <input
                                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                            id="email" type="email" placeholder="Your Email Address"
                                            value={bid.nickname} onChange={updateNickName}/>
                                        <p className="text-red-500 text-xs italic">{errorEmail}</p>
                                    </div>
                                    <div className="mb-6">
                                        <label className="block text-gray-700 text-sm font-bold mb-2"
                                               htmlFor="name">
                                            Minimum Bid (ETH)
                                        </label>
                                        <input
                                            className="shadow appearance-none border border-red-500 rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                                            id="name" type="text" placeholder="Your nick name" value={bid.amount}
                                            onChange={updatePrice}/>
                                        <p className="text-red-500 text-xs italic">{errorName}</p>
                                    </div>
                                </form>
                                <button onClick={submitBid}
                                        className="p-4 middle none center mr-2 rounded-lg bg-blue-500 py-3 px-2 font-sans text-l font-bold uppercase text-white shadow-md shadow-blue-500/20 transition-all hover:shadow-lg hover:shadow-blue-500/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none">Submit Bid
                                </button>
                            </div>
                        </div>

                    </Box>
                </Modal>
                <div className="h-[300px] lg:w-[27%] w-[80%] object-cover bg-cover bg-center rounded-lg  bg-[url('assets/ccNFT/auth-bg.png')] ">
                    <div className=" flex flex-col justify-center items-center  lg:mt-10 mt-4 ">
                        <div className=" lg:text-xl  text-sm font-medium px-16 mb-5">
                            ECSMID color-coded encrypted non-fungible Token (cceNFT)
                        </div>
                        <img src={Vid} alt="videos"/>
                    </div>
                </div>
            </div>
            <div>
                <h4 className="text-[20px] font-semibold my-6 ">Top NFTs for sale</h4>
                <div
                    className=" grid lg:grid-cols-3 grid-cols-1  lg:mx-32 mx-0 mr-7 lg:mr-0 gap-5 lg:space-y-0 space-y-2 mb-8">
                    {
                        bidNFTs.length === 0 ?
                            <div className="p-4 m-4"><p className="text-2xl text-warning p-4 m-4">There are no NFTs listed for sale yet, list one to get started</p>
                                <a
                                    className="p-4 m-4 flex py-2 px-2 md:w-[9rem]  items-center   w-28 rounded-2xl lg:text-sm text-xs text-black bg-[#FFB613]  font-extrabold" href="/mint">
                <span className="mr-1">
                  <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M2.8041 8.353C2.5241 10.956 2.5361 13.958 2.9261 16.55C3.03079 17.2469 3.36698 17.8882 3.88052 18.3707C4.39407 18.8532 5.05508 19.1489 5.7571 19.21L7.2671 19.341C10.4171 19.615 13.5831 19.615 16.7331 19.341L18.2431 19.211C18.9451 19.1499 19.6061 18.8542 20.1197 18.3717C20.6332 17.8892 20.9694 17.2479 21.0741 16.551C21.4641 13.958 21.4761 10.956 21.1961 8.353C21.16 8.05107 21.1193 7.74971 21.0741 7.449C20.9694 6.75215 20.6332 6.11081 20.1197 5.62828C19.6061 5.14575 18.9451 4.85012 18.2431 4.789L16.7331 4.659C13.5837 4.3852 10.4165 4.3852 7.2671 4.659L5.7571 4.789C5.05508 4.85012 4.39407 5.14575 3.88052 5.62828C3.36698 6.11081 3.03079 6.75215 2.9261 7.449C2.88102 7.74972 2.84035 8.05109 2.8041 8.353ZM7.3971 6.153C10.4597 5.88677 13.5395 5.88677 16.6021 6.153L18.1121 6.284C18.4787 6.31601 18.8239 6.47039 19.0922 6.72233C19.3604 6.97427 19.5362 7.30911 19.5911 7.673L19.6251 7.906L14.0641 10.996C13.4327 11.3468 12.7224 11.5308 12.0001 11.5308C11.2778 11.5308 10.5675 11.3468 9.9361 10.996L4.3751 7.906L4.4091 7.673C4.46365 7.30912 4.63913 6.9742 4.90725 6.72222C5.17538 6.47024 5.52054 6.31588 5.8871 6.284L7.3971 6.153ZM19.8081 9.52C20.0025 11.7897 19.9296 14.0743 19.5911 16.327C19.5366 16.6909 19.3611 17.0258 19.0929 17.2778C18.8248 17.5298 18.4797 17.6841 18.1131 17.716L16.6031 17.847C13.5402 18.1132 10.46 18.1132 7.3971 17.847L5.8871 17.716C5.52054 17.6841 5.17538 17.5298 4.90725 17.2778C4.63913 17.0258 4.46365 16.6909 4.4091 16.327C4.07025 14.0744 3.99709 11.7897 4.1911 9.52L9.2071 12.307C10.0613 12.7816 11.0224 13.0306 11.9996 13.0306C12.9768 13.0306 13.9379 12.7816 14.7921 12.307L19.8081 9.52Z"
                        fill="#33240A"
                    />
                  </svg>
                </span>{" "}
                                    MINT cceNFT
                                </a>
                            </div> :
                            bidNFTs.map((val, index, array) => {
                                const value=val.nft;
                                return (
                                    <div key={index}
                                         className=" lg:w-[325px] w-full  h-[430px] bg-black  rounded-xl flex flex-col justify-center items-center ">

                                        <div className="flex text-xs justify-between space-x-12 px-8 mb-3 items-center">
                                            <div className=" ">
                                                ECSMID Digital Codex NFT III <br/>
                                                Owner : cceNFT - {userData.name}
                                            </div>
                                            <div className="flex space-x-1 items-center">
                                                <svg
                                                    width="17"
                                                    height="14"
                                                    viewBox="0 0 17 14"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M12.1638 0C10.6917 0 9.38517 0.555087 8.5 1.50916C7.61483 0.555087 6.30832 0 4.83621 0C3.55404 0.00148193 2.32483 0.488695 1.41821 1.35477C0.511577 2.22085 0.00155132 3.39507 0 4.61989C0 9.68357 7.75332 13.7295 8.08306 13.8996C8.2112 13.9655 8.35446 14 8.5 14C8.64554 14 8.7888 13.9655 8.91694 13.8996C9.24668 13.7295 17 9.68357 17 4.61989C16.9984 3.39507 16.4884 2.22085 15.5818 1.35477C14.6752 0.488695 13.446 0.00148193 12.1638 0ZM11.7615 9.96496C10.741 10.7922 9.65003 11.5368 8.5 12.1909C7.34997 11.5368 6.25903 10.7922 5.23849 9.96496C3.6506 8.66369 1.75862 6.67924 1.75862 4.61989C1.75862 3.84017 2.08287 3.09239 2.66002 2.54105C3.23718 1.9897 4.01998 1.67996 4.83621 1.67996C6.14052 1.67996 7.23233 2.33794 7.68591 3.39772C7.75192 3.55221 7.86461 3.68442 8.00961 3.77748C8.1546 3.87054 8.32532 3.92024 8.5 3.92024C8.67468 3.92024 8.8454 3.87054 8.99039 3.77748C9.13539 3.68442 9.24808 3.55221 9.3141 3.39772C9.76767 2.33794 10.8595 1.67996 12.1638 1.67996C12.98 1.67996 13.7628 1.9897 14.34 2.54105C14.9171 3.09239 15.2414 3.84017 15.2414 4.61989C15.2414 6.67924 13.3494 8.66369 11.7615 9.96496Z"
                                                        fill="white"
                                                        fill-opacity="0.8"
                                                    />
                                                </svg>
                                                <span>212</span>
                                            </div>
                                        </div>
                                        <div
                                            className="lg:w-[280px]  w-[80%] h-[300px] object-cover bg-cover bg-center rounded-lg"
                                            style={{
                                                backgroundImage:
                                                    'url("./assets/images/abstract-flowing-neon-wave-background 13.png")',
                                            }}
                                        >
                                            <div className="flex justify-between px-1 mt-2">
                                                <div className="p-2 rounded-full">
                                                    { <button onClick={(e)=>buyNFT(index)} className="bg-primary hover:bg-blue-700 text-black-50 font-bold py-2 px-4 rounded">Buy</button> }
                                                </div>
                                                {/*<div className=" bg-[#FAFBFC] text-black p-2 text-sm rounded-xl ">
                                    02 : 10 : 40 : 52 left
                                </div>*/}
                                            </div>
                                            <img src={value.image} className="mt-4"/>
                                            <img src={verify} alt="" className="h-14 w-14  ml-40 -mt-10"/>
                                        </div>
                                        <div
                                            className="flex text-xs font-semibold justify-between  space-x-10 mt-7 px-1">
                                            <div>
                                                Current Bid <br/> {val.bid} ETH
                                            </div>
                                            <div>ECSMID - NFT III</div>
                                        </div>
                                    </div>
                                )
                            })
                    }
                </div>
            </div>

        </section>
    );
}
export default Dashboard;