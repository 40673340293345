import React, {useEffect, useState} from "react";
import md5 from "md5";
import '../utils/ecsmid';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Settings() {
  const [gravatar,setGravatar]=useState("https://i.ibb.co/rxcmjCw/3.webp");
  const [avatar, setAvatar]=useState("");
  const [email, setEmail]=useState("");
  const [nickname, setNickName]=useState("");
  useEffect(()=>{
    window.main();
    toast.configure();
    if(localStorage.getItem("email") && email.length===0){
      const e=decrypt(localStorage.getItem("email"));
      setEmail(e);
      let hash=md5(e.toLowerCase());
      downloadGravatar(hash);
    }
    if(localStorage.getItem("name") && nickname.length===0){
      console.log("setting name");
      setNickName(decrypt(localStorage.getItem("name")));
    }
    if(localStorage.getItem("avatar") && avatar.length===0){
      setAvatar(localStorage.getItem("avatar"));
    }
  });
  function downloadGravatar(emailHash){
    setGravatar("https://www.gravatar.com/avatar/" + emailHash + "?s=80");
  }
  function encrypt(val) {
    let encrypted=window.main.api.encrypt(val);
    let i=10;
    while(val != decrypt(encrypted) && i>0){
      encrypted=window.main.api.encrypt(val);
      i--;
    }
    return encrypted;
  }

  function decrypt(val) {
    return window.main.api.decrypt(val);
  }
  function nameChanged(e){
    setNickName(e.target.value);
    localStorage.setItem("name",encrypt(e.target.value));
  }
  function emailChanged(e){
    setEmail(e.target.value);
    localStorage.setItem("email",encrypt(e.target.value));
  }
  function showErr(msg) {

    toast(msg, {
      type: "error",
    });
  }
  function showInfo(msg) {
    toast(msg, {
      type: "success"
    });
  }

  function save(e) {
    e.preventDefault();
    showInfo("Settings updated successfully");
  }

  function handleFile(e) {
    const file = e.target.files[0];

    // Create a FileReader object
    const reader = new FileReader();

    // Define a callback function to run when the file is loaded
    reader.onload = () => {
      // Get the base64 string from the result property
      const base64 = reader.result;

      // Store the base64 string in the local storage
      localStorage.setItem("avatar", base64);

      // Set the state variable to the base64 string
      setAvatar(base64);
    };

    // Read the file as a data URL
    reader.readAsDataURL(file);
  }

  return (
    <div>
      <div className="text-3xl font-semibold ml-8 mb-7"> Settings</div>
      <div>
        <form method="post" className=" pt-8 px-8">
          <div>
            <div className="grid grid-cols-2 gap-4">
              <div className="w-full my-4 ">
                <label className="mt-6">Site Theme</label>
                <div>
                <input
                  type="radio"
                  name="theme"
                  //   value={formData.firstName}
                  //   onChange={handleChange}

                  placeholder="Black"
                  required={true}
                  checked={true}
                  className=""
                />Dark
                </div>
                <div>
                  <input
                      type="radio"
                      name="theme"
                      //   value={formData.firstName}
                      //   onChange={handleChange}

                      placeholder="Black"
                      required={true}
                      className=""
                  />Light
                </div>
              </div>

              <div className="my-4">
                <label className="mt-6">Nick Name</label>
                <input
                  type="text"
                  name="nickname"
                  placeholder="Your Nick Name"
                  required={true}
                  value={nickname}
                  onChange={nameChanged}
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                />
              </div>
            </div>
            <div className="grid lg:grid-cols-2 grid-cols-1 gap-4">
              <div className=" my-4">
                <label className="mt-6">Preferred Wallet</label>
                <div>
                  <input
                      type="radio"
                      name="wallet"
                      //   value={formData.firstName}
                      //   onChange={handleChange}

                      placeholder="Black"
                      required={true}
                      checked={true}
                      className=""
                  />Metamask
                </div>
                <div>
                  <input
                      type="radio"
                      name="wallet"
                      //   value={formData.firstName}
                      //   onChange={handleChange}

                      placeholder="Black"
                      required={true}
                      className=""
                  />Coinbase
                </div>
              </div>
              <div className=" my-4">
                <label className="mt-6">Email</label>
                <input
                  type="email"
                  name="email"
                  required={true}
                  placeholder="Email"
                  value={email}
                  onChange={emailChanged}
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                />
              </div>
            </div>

            <div className="grid lg:grid-cols-2 grid-cols-1 gap-4">
              <div className=" my-4">
                <label className="mt-6">Update Profile</label>
                <img width="100" height="100" className="p-2" src={avatar.length >0 ? avatar:gravatar} />
                <input
                  type="file"
                  name="avatar"
                  placeholder="Avatar"
                  required={true}
                  onChange={handleFile}
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                />
              </div>
            </div>

            <div className="mt-6  flex  items-end justify-end   ">
              <button
                type="submit"
                onClick={save}
                className={`
                    inline-block w-[20%]  rounded dark:bg-white  bg-white mb-10  px-7 pb-2.5 pt-3 text-sm  uppercase leading-normal  text-gray-500 font-bold shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out  hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)
              
            `}
              >
                Save Changes
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Settings;
