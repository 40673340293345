import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";

import { BsArrowLeftCircle } from "react-icons/bs";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHeart,
  faSquarePollVertical,
  faWallet,
  faBookBookmark,
  faUser,
  faGear,
  faSquare,
  faGem,
  faChevronRight,
  faChevronLeft,
} from "@fortawesome/free-solid-svg-icons";
import HamburgerButton from "./HamburgerMenuButton/HamburgerButton";

const Sidebar = () => {
  const [open, setOpen] = useState(true);
  const [mobileMenu, setMobileMenu] = useState(false);
  const location = useLocation();

  const Menus = [
    {
      title: "Dashboard",
      path: "/",
      src: (
        <FontAwesomeIcon
          // icon="fa-solid fa-square-poll-vertical"
          icon={faSquarePollVertical}
          color=""
        />
      ),
    },
    {
      title: "MINT cceNFT",
      path: "/mint",
      src: <FontAwesomeIcon icon={faSquare} color="" />,
    },
    {
      title: "AUTHY DEMO",
      path: "/auth",
      src: <FontAwesomeIcon icon={faGem} color="" />,
    },
    {
      title: "FAVOURITE NFTs",
      path: "/favourites",
      src: <FontAwesomeIcon icon={faHeart} color="" />,
      gap: "true",
    },
    {
      title: "My cceNFTs",
      path: "/nft",
      src: <FontAwesomeIcon icon={faWallet} color="" />,
      gap: "#",
    },
    {
      title: "MY ACTIVE BIDS",
      path: "/bids",
      src: <FontAwesomeIcon icon={faBookBookmark} color="" />,
      gap: "true",
    },
    {
      title: "MY PORTFOLIO",
      path: "#",
      src: <FontAwesomeIcon icon={faUser} color="" />,
      gap: "true",
    },
    {
      title: "SETTING",
      path: "/setting",
      src: <FontAwesomeIcon icon={faGear} color="" />,
      gap: "true",
    },
  ];

  return (
    <>
      <div
        className={`${
          open ? "w-60" : "w-fit"
        } hidden sm:block relative  w-80 duration-300   border-r border-gray-200  dark:border-gray-600 p-5 `}
      >
        <BsArrowLeftCircle
          className={`${
            !open && "rotate-180"
          } absolute text-3xl bg-white fill-slate-800  rounded-full cursor-pointer top-9 -right-4 dark:fill-gray-400 dark:bg-gray-800`}
          onClick={() => setOpen(!open)}
        />
        <Link to="/">
          <div className={`flex ${open && "gap-x-4"} items-center`}>
            {/* <img src={Logo} alt='' className='pl-2' /> */}
            {/* <img
              src="/styles/images/logo.png"
              alt="logo"
              className=" h-20 w-20 "
            /> */}
            {open && (
              //   <span className="text-xl font-medium whitespace-nowrap dark:text-white">
              //     Goal Quest
              //   </span>
              <img
                src="/styles/images/logo.png"
                alt="logo"
                className=" h-32 w-40 "
              />
            )}
          </div>
        </Link>

        <ul className="pt-2">
          {Menus.map((menu, index) => (
            <Link to={menu.path} key={index}>
              <li
                className={`flex items-center gap-x-6 p-3 text-base font-normal rounded-lg cursor-pointer dark:text-gray-100 hover:bg-gray-200 dark:hover:bg-gray-100 dark:hover:text-gray-800 hover:text-gray-800
                        ${menu.gap ? "mt-2" : "mt-2"} ${
                  location.pathname === menu.path &&
                  "bg-gray-200 dark:bg-gray-100 dark:text-gray-800  text-gray-800"
                }`}
              >
                <span className="text-2xl">{menu.src}</span>
                <span
                  className={`${
                    !open && "hidden"
                  } origin-left duration-300 hover:block`}
                >
                  {menu.title}
                </span>
              </li>
            </Link>
          ))}
        </ul>
        {open && (
          <div
            className=" w-[200px] h-[195px] mt-10 flex flex-col justify-center items-center "
            style={{
              borderRadius: "3px",
              background:
                "linear-gradient(97deg, #26134F 3.86%, #B43B78 80.87%)",
            }}
          >
            <p className="text-center text-xl px-6 mb-1 font-medium">
              Enter your mail to get product update.
            </p>
            <input
              type="email"
              className="w-40 px-2 bg-black text-white  text-sm py-1 rounded-sm"
              placeholder="Enter your email here"
            />
          </div>
        )}
        {/* {open && (
          <div
            className=" w-[200px] h-[1px] mt-10 flex flex-col justify-center items-center "
            style={{
              borderRadius: "3px",
              background: "black",
            }}
          >
            
            <input
              type="email"
              className="w-40 px-2 bg-black text-white  text-sm py-1 rounded-sm"
              placeholder="Enter your email here"
            />
          </div>
        )} */}
      </div>
      {/* Mobile Menu */}
      <div className="pt-3">
        <HamburgerButton
          setMobileMenu={setMobileMenu}
          mobileMenu={mobileMenu}
        />
      </div>
      <div className="sm:hidden">
        <div
          className={`${
            mobileMenu ? "flex" : "hidden"
          } absolute z-50 flex-col items-center self-end py-8 mt-16 space-y-6 font-bold sm:w-auto left-6 right-6 dark:text-white  bg-slate-800 dark:bg-slate-800 drop-shadow md rounded-xl`}
        >
          {Menus.map((menu, index) => (
            <Link
              to={menu.path}
              key={index}
              onClick={() => setMobileMenu(false)}
            >
              <span
                className={` ${
                  location.pathname === menu.path &&
                  "bg-gray-700 dark:bg-gray-700"
                } p-2 rounded-xl hover:bg-gray-200 dark:hover:bg-gray-700`}
              >
                {menu.title}
              </span>
            </Link>
          ))}
        </div>
      </div>
    </>
  );
};

export default Sidebar;
