
import React from 'react'
import { useState } from 'react'
import { faArrowDown, faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const minusIcon = <FontAwesomeIcon icon={faArrowUp} />
const plusIcon = <FontAwesomeIcon icon={faArrowDown} />

const Accordion = ({ title, content }) => {
    const [expanded, setExpanded] = useState(false)
    const toggleExpanded = () => setExpanded((current) => !current)
    return (
        <div className=" mx-5 -mt-20 md:-mt-28 shadow-sm cursor-pointer bg-white text-black rounded-lg absolute" onClick={toggleExpanded}>
            <div className="px-6 text-left items-center h-20 select-none flex justify-between flex-row text-2xl" style={{ border: "1px solid #69163D", borderRadius: '8px' }}>
                <h5 className="flex-1  " >
                    {title}
                </h5>
                <div className="flex-none pl-2">{expanded ? minusIcon : plusIcon}</div>
            </div>
            <div className={`px-6 pt-0 overflow-hidden transition-[max-height] duration-500 ease-in ${expanded ? "max-h-full" : "max-h-0"}`}>
                <p className="pb-4 text-left">
                    In layman's terms, cceNFTs are a special type of digital asset that use encryption and unique visual features to make them unique and verifiable.</p>
                <p className="pb-4 text-left">These digital assets can be used to represent ownership of different things like art, videos, music and many more. </p>
                <p className="pb-4 text-left">They can also be used in places where internet connectivity is not available, this means that they can be verified without the need of the internet.</p>
                <p className="pb-4 text-left">They are similar to traditional NFTs, but they have added security and visual differentiation features that makes them unique.</p>
            </div>
        </div>
    )
}

export default Accordion