import React, {useEffect, useState} from "react";
import md5 from "md5";

function TopBar({name,email}) {
    useEffect(()=>{
        console.log("props",name,email);
    },[]);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [dropdownOpen1, setDropdownOpen1] = useState(false);
    const [dropdownOpen2, setDropdownOpen2] = useState(false);
    const [avatar,setAvatar]=useState("https://i.ibb.co/rxcmjCw/3.webp");
    useEffect(()=>{
        if(localStorage.getItem("avatar")){
            setAvatar(localStorage.getItem("avatar"));
        }
        else if(email && email.length>0){
            let hash=md5(email.toLowerCase());
            downloadGravatar(hash);
        }
    },[email])
    function downloadGravatar(emailHash){
        setAvatar("https://www.gravatar.com/avatar/" + emailHash + "?s=80");
    }
    return (
        <header className="flex items-center justify-between px-6 py-4 ">
            <div className="flex items-center justify-between space-x-20">
                {/* <button
                    type="button"
                    onClick={() => toggleSidebar(true)}
                    className="text-gray-500 focus:outline-none lg:hidden">
                    <svg
                        className="w-6 h-6"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M4 6H20M4 12H20M4 18H11"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </svg>
                </button> */}
                {/* <button
                    type="button"
                    onClick={() => document.body.classList.toggle('mini-sidebar')}
                    className="text-gray-500 focus:outline-none hidden lg:block">
                    <svg
                        className="w-6 h-6"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M4 6H20M4 12H20M4 18H11"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </svg>
                </button> */}
                <p className="pl-10">Hi, {name} Welcome Back!</p>
                <div class=" lg:block hidden">
                    <form method="GET">
                        <div class="relative text-gray-600 focus-within:text-gray-400">
                            <input
                                type="search"
                                name="q"
                                class="py-2 text-sm text-black bg-gray-100 rounded-md pl-10 focus:outline-none "
                                placeholder="Search..."
                                autocomplete="off"
                            />
                            <span class="absolute inset-y-0 right-0 flex items-center pr-2">
                <button
                    type="submit"
                    class="p-1 focus:outline-none focus:shadow-outline"
                >
                  <svg
                      fill="none"
                      stroke="black"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      viewBox="0 0 24 24"
                      class="w-6 h-6"
                  >
                    <path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
                  </svg>
                </button>
              </span>
                        </div>
                    </form>
                </div>
            </div>

            <div className="flex items-center space-x-4 ">
                <a href="/mint" className="lg:block hidden py-2 px-2 w-32 rounded-2xl text-black bg-[#FFB613]  font-bold">
                    MINT cceNFT
                </a>
                <button
                    type="button"
                    //   onClick={() => document.documentElement.classList.toggle("dark")}
                    className=""
                >
                    {/*  */}
                    <svg
                        width="25"
                        height="25"
                        viewBox="0 0 45 45"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <circle cx="22.5" cy="22.5" r="22.5" fill="#FAFBFC" />
                        <circle
                            cx="18.5"
                            cy="16.5"
                            r="3.6"
                            stroke="black"
                            stroke-width="1.8"
                        />
                        <circle
                            cx="27.5"
                            cy="28.5"
                            r="3.6"
                            stroke="black"
                            stroke-width="1.8"
                        />
                        <circle
                            cx="28.5"
                            cy="17.5"
                            r="2.6"
                            stroke="#7743FF"
                            stroke-width="1.8"
                        />
                        <circle
                            cx="17.5"
                            cy="28.5"
                            r="2.6"
                            stroke="#7743FF"
                            stroke-width="1.8"
                        />
                    </svg>
                </button>
                <div className="relative">
                    <button
                        type="button"
                        onClick={() => setDropdownOpen1(!dropdownOpen1)}
                        className=""
                    >
                        <svg
                            width="25"
                            height="25"
                            viewBox="0 0 45 45"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <circle cx="22.5" cy="22.5" r="22.5" fill="#FAFBFC" />
                            <path
                                d="M27.4375 28.6667H34L32.1559 26.7933C31.9083 26.5417 31.7119 26.243 31.5779 25.9142C31.4439 25.5855 31.3749 25.2331 31.375 24.8773V20.6667C31.3752 19.0119 30.8704 17.3978 29.9302 16.0465C28.99 14.6952 27.6606 13.6733 26.125 13.1213V12.6667C26.125 11.9594 25.8484 11.2811 25.3562 10.781C24.8639 10.281 24.1962 10 23.5 10C22.8038 10 22.1361 10.281 21.6438 10.781C21.1516 11.2811 20.875 11.9594 20.875 12.6667V13.1213C17.8169 14.22 15.625 17.184 15.625 20.6667V24.8787C15.625 25.596 15.3441 26.2853 14.8441 26.7933L13 28.6667H19.5625M27.4375 28.6667V30C27.4375 31.0609 27.0227 32.0783 26.2842 32.8284C25.5458 33.5786 24.5443 34 23.5 34C22.4557 34 21.4542 33.5786 20.7158 32.8284C19.9773 32.0783 19.5625 31.0609 19.5625 30V28.6667M27.4375 28.6667H19.5625"
                                stroke="#7743FF"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                            <circle cx="40.5" cy="10.5" r="4.5" fill="#F63D13" />
                        </svg>
                    </button>
                    <button></button>
                    {dropdownOpen1 && (
                        <div>
                            <div
                                role="button"
                                tabIndex="0"
                                onClick={() => setDropdownOpen1(false)}
                                className="fixed inset-0 h-full w-full z-10"
                            />

                            <div
                                className="absolute right-0 mt-2 bg-white rounded-md shadow-lg overflow-hidden z-20"
                                style={{ width: "20rem" }}
                            >
                                <div className="py-2">
                                    <a
                                        href="/"
                                        className="flex items-center px-4 py-3 border-b hover:bg-gray-100 -mx-2"
                                    >
                                        <img
                                            className="h-8 w-8 rounded-full object-cover mx-1"
                                            src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=334&q=80"
                                            alt="avatar"
                                        />
                                        <p className="text-gray-600 text-sm mx-2">
                      <span className="font-bold" href="#">
                        Sara Salah
                      </span>{" "}
                                            replied on the{" "}
                                            <span className="font-bold text-blue-500" href="#">
                        Upload Image
                      </span>{" "}
                                            article . 2m
                                        </p>
                                    </a>
                                    <a
                                        href="/"
                                        className="flex items-center px-4 py-3 border-b hover:bg-gray-100 -mx-2"
                                    >
                                        <img
                                            className="h-8 w-8 rounded-full object-cover mx-1"
                                            src="https://images.unsplash.com/photo-1531427186611-ecfd6d936c79?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=634&q=80"
                                            alt="avatar"
                                        />
                                        <p className="text-gray-600 text-sm mx-2">
                      <span className="font-bold" href="#">
                        Slick Net
                      </span>{" "}
                                            start following you . 45m
                                        </p>
                                    </a>
                                    <a
                                        href="/"
                                        className="flex items-center px-4 py-3 border-b hover:bg-gray-100 -mx-2"
                                    >
                                        <img
                                            className="h-8 w-8 rounded-full object-cover mx-1"
                                            src="https://images.unsplash.com/photo-1450297350677-623de575f31c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=334&q=80"
                                            alt="avatar"
                                        />
                                        <p className="text-gray-600 text-sm mx-2">
                      <span className="font-bold" href="#">
                        Jane Doe
                      </span>{" "}
                                            Like Your reply on{" "}
                                            <span className="font-bold text-blue-500" href="#">
                        Test with TDD
                      </span>{" "}
                                            article . 1h
                                        </p>
                                    </a>
                                    <a
                                        href="/"
                                        className="flex items-center px-4 py-3 hover:bg-gray-100 -mx-2"
                                    >
                                        <img
                                            className="h-8 w-8 rounded-full object-cover mx-1"
                                            src="https://images.unsplash.com/photo-1580489944761-15a19d654956?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=398&q=80"
                                            alt="avatar"
                                        />
                                        <p className="text-gray-600 text-sm mx-2">
                      <span className="font-bold" href="#">
                        Abigail Bennett
                      </span>{" "}
                                            start following you . 3h
                                        </p>
                                    </a>
                                </div>
                                <a
                                    href="/"
                                    className="block bg-gray-800 text-white text-center font-bold py-2"
                                >
                                    See all notifications
                                </a>
                            </div>
                        </div>
                    )}
                </div>

                <div className="relative">
                    <button
                        type="button"
                        onClick={() => setDropdownOpen(!dropdownOpen)}
                        className="relative z-10 block w-8 h-8 mr-2 overflow-hidden rounded-full shadow focus:outline-none"
                    >
                        <img
                            className="object-cover w-full h-full"
                            src={avatar}
                            alt="Your avatar"
                        />
                    </button>

                    {dropdownOpen && (
                        <div>
                            <div
                                role="button"
                                tabIndex="0"
                                onClick={() => setDropdownOpen(false)}
                                className="fixed inset-0 z-10 w-full h-full"
                            />

                            <div className="absolute right-0 z-20 w-48 py-2 mt-2 bg-white rounded-md shadow-xl">
                                <a
                                    href="/settings"
                                    className="block px-4 py-2 text-sm text-gray-700 hover:bg-indigo-600 hover:text-white"
                                >
                                    Settings
                                </a>
                                <a
                                    href="/nft"
                                    className="block px-4 py-2 text-sm text-gray-700 hover:bg-indigo-600 hover:text-white"
                                >
                                    My cceNFTs
                                </a>
                                <button
                                    onClick={(e) => {
                                        e.preventDefault();
                                        localStorage.clear();
                                        window.location.replace("/");
                                     }}
                                    className="block px-4 py-2 text-sm text-gray-700 hover:bg-indigo-600 hover:text-white"
                                >
                                    Log out
                                </button>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </header>
    );
}

export default TopBar;