import React, {useEffect, useState} from "react";
import setting from "../images/setting.png";
import md5 from "md5";
import {getCurrentWalletConnected} from "../utils/api";
import 'bootstrap/dist/css/bootstrap.css';

function Bids() {
    const SERVER = window.location.href.includes("localhost") ? "http://localhost:9004":"https://ccnft.lokdon.com";
    const [name, setName]=useState("");
    const [avatar, setAvatar]=useState("https://i.ibb.co/rxcmjCw/3.webp");
    const [bids, setBids]=useState([]);
    const [walletAddress, setWalletAddress] = useState("");
    useEffect(()=>{
        window.main();
        setTimeout(() => {
            getCurrentWalletConnected()
                .then((result) => {
                    if (result.address.length > 0) {
                        //console.log("wallet connected, modal is", open);
                        setWalletAddress(result.address);
                    } else {

                        if (walletAddress.length === 0) {
                            console.log("Wallet NOT connected, opening modal");
                        }
                    }
                })
                .catch((err) => {
                    console.log("wallet not connected");
                });
        }, 1000);

        addWalletListener();
        if(localStorage.getItem("name") && localStorage.getItem("email")){
            setName(decrypt(localStorage.getItem("name")));
            let avatar="https://www.gravatar.com/avatar/" + md5(decrypt(localStorage.getItem("email"))) + "?s=80";
            setAvatar(avatar);
        }
    },[]);
    useEffect(()=>{
        if(walletAddress.length>0)
            collectBids();
    },[walletAddress]);
    function collectBids(){
        fetch(SERVER+"/api/auction/bids/"+walletAddress, {
            method: "GET"
        })
            .then(result=>result.json())
            .then((result)=>{
                if(result.status==='success'){
                    setBids([...result.data]);
                }else{

                }
            })
    }
    function addWalletListener() {
        if (window.ethereum) {
            window.ethereum.on("accountsChanged", (accounts) => {
                if (accounts.length > 0) {
                    setWalletAddress(accounts[0]);
                } else {
                    if (walletAddress.length > 0) {
                        //setWalletAddress(walletAddress);
                    } else {
                        setWalletAddress("");
                    }
                }
            });
        } else {

        }
    }

    function decrypt(val){
        return window.main.api.decrypt(val);
    }
    return (
        <div
            className="h-full   w-full flex object-cover bg-cover bg-center rounded-lg"
            style={{
                backgroundImage: 'url("../assets/images/bg_settings.png")',
            }}
        >
            <div className=" flex flex-col h-[90vh] grow justify-between w-[18%] border-r">
                <div className="flex justify-center items-center lg:text-xl md:text-sm text-xs font-semibold uppercase mt-10">
                    My ACTIVE BIDS
                </div>
                <div className="flex lg:flex-row md:flex-col flex-col  mx-auto items-center mb-10">
                    <div className="relative z-10 block w-8 h-8 mr-2 overflow-hidden rounded-lg shadow focus:outline-none">
                        <img
                            className="object-cover w-full h-full"
                            src={avatar}
                            alt="Your avatar"
                        />
                    </div>

                    <div className="md:text-sm text-xs mx-2">
                        <div>{name}</div>
                        <div>TRADE ACCOUNT</div>
                    </div>
                </div>
            </div>
            <div className=" lg:w-[80%] w-[70%] flex flex-col h-[90vh]">
                <div className="md:h-[25%] h-[10%] border-b "></div>
                <div className="md:h-[45%] h-[80%] border-b  ">
                    <table className="table table-responsive table-auto table-fixed border-separate">
                        <thead>
                        <tr>
                            <th>Token ID</th>
                            <th>Amount (ETH)</th>
                            <th>ESCROW Status</th>
                            <th>Actions</th>
                        </tr>
                        </thead>
                        <tbody>
                    {
                        bids.length===0 ? <p>You haven't bid on any NFTs yet, start bidding on NFTs you like from the dashboard page</p>:

                            bids.map((value, index)=>{
                                return (
                                    <tr key={index}>
                                        <td>{value.tokenId}</td>
                                        <td>{value.amount}</td>
                                        <td>{value.paymentDone ? "Paid":"Awaiting payment"}</td>
                                        <td>
                                            {
                                                value.paymentDone ? <button className="btn btn-danger" disabled={true}>Withdraw Bid</button>:<button className="btn btn-primary">Pay ESCROW</button>
                                            }
                                        </td>
                                    </tr>
                                )
                            })

                    }
                        </tbody>
                    </table>

                </div>
                <div className="">
                    <img src={setting} alt="" className=" h-[10rem]" />
                </div>
            </div>
        </div>
    );
}

export default Bids;
